import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IoMdEyeOff } from "react-icons/io";
import { PersonalInfoValidation } from "../../utils/validation";
import Resizer from "react-image-file-resizer";
function PersonalInfo({
  setFullnames,
  setPhoneNumber,
  setEmail,
  setProfilePic,
  setPassword,
  setConfirmPassword,
  fullnames,
  phoneNumber,
  email,
  password,
  confirmPassword,
  profilePic,
  next,
}) {
  const { errors, isRegistering } = useSelector(({ UserAuth }) => UserAuth);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [profilePicPreview, setProfilePicPreview] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  console.log("ProfilePic", profilePic);
  return (
    <form className="mt-8 space-y-6" id="submit-login">
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="space-y-6">
        <div>
          <label htmlFor="fullName" className="sr-only">
            Full names
          </label>
          <input
            id="fullName"
            name="fullName"
            type="text"
            autoComplete="fullName"
            value={fullnames}
            onChange={(e) => setFullnames(e.target.value)}
            required
            className="appearance-none rounded-none relative block w-full px-3 py-3 placeholder-darkblue text-darkblue rounded-t-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
            placeholder="Enter Your full Names"
          />
          {(formErrors && formErrors?.fullnames) || errors?.full_names ? (
            <div className="rounded border border-danger px-3 py-2 mt-1 mb-2 text-xs bg-red-100">
              {formErrors?.fullnames || errors?.pfull_names}
            </div>
          ) : null}
        </div>
        <div>
          <label htmlFor="phoneNumber" className="sr-only">
            Phone Number
          </label>
          <input
            id="phoneNumber"
            name="phoneNumber"
            type="text"
            autoComplete="phoneNumber"
            required
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-3 placeholder-darkblue text-darkblue rounded-b-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
            placeholder="Phone number"
          />
          {(formErrors && formErrors?.phoneNumber) || errors?.phone_number ? (
            <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
              {formErrors?.phoneNumber || errors?.phone_number}
            </div>
          ) : null}
        </div>
        <div>
          <label htmlFor="email" className="sr-only">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-3 placeholder-darkblue text-darkblue rounded-b-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
            placeholder="Email"
          />
          {(formErrors && formErrors?.email) || errors?.email ? (
            <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
              {formErrors?.email || errors?.email}
            </div>
          ) : null}
        </div>
      </div>
      <div className="flex items-center bg-white">
        <label htmlFor="password" className="sr-only">
          Password
        </label>
        <input
          id="password"
          name="password"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          required
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          className="flex-1 appearance-none rounded-none relative block w-full px-3 py-2 placeholder-darkblue text-gray-900 rounded-b-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
          placeholder="Enter password"
        />

        <IoMdEyeOff
          role="button"
          className="text-darkblue mx-3 text-xl"
          onClick={() => setShowPassword(!showPassword)}
        />
      </div>
      {(formErrors && formErrors?.password) || errors?.password ? (
        <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
          {formErrors?.password || errors?.password}
        </div>
      ) : null}

      <div className="flex items-center bg-white">
        <label htmlFor="password" className="sr-only">
          Confirm Password
        </label>
        <input
          id="confirm-password"
          name="confirm-password"
          type={showConfirmPassword ? "text" : "password"}
          autoComplete="confirm-password"
          required
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          className="flex-1 appearance-none rounded-none relative block w-full px-3 py-2 placeholder-darkblue text-gray-900 rounded-b-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
          placeholder="Confirm password"
        />

        <IoMdEyeOff
          role="button"
          className="text-darkblue mx-3 text-xl"
          onClick={() => setShowConfirmPassword(!showConfirmPassword)}
        />
      </div>
      {(formErrors && formErrors?.confirmPassword) ||
      errors?.password_confirmation ? (
        <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
          {formErrors?.confirmPassword || errors?.password_confirmation}
        </div>
      ) : null}
      <div>
        <input
          type="file"
          id="profilePic"
          onChange={(e) => {
            setProfilePic(e.target.files[0]);
            setProfilePicPreview(URL.createObjectURL(e.target.files[0]));
          }}
          hidden
        />

        <label
          htmlFor="profilePic"
          className="overflow-auto cursor-pointer rounded-xl   border-dashed py-3 bg-white px-3  w-100 flex justify-between items-center"
        >
          <div>
            <p className="text-sm text-darkblue  ">
              Profile Picture (480px*480){" "}
            </p>
            <p className="text-sm  text-red-500">{profilePic?.name ?? " "} </p>
          </div>
          <svg
            width="42"
            height="39"
            viewBox="0 0 42 39"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className="w-6"
          >
            <path
              d="M5.47826 5.57143V0L9.13043 0V5.57143H14.6087V9.28571H9.13043V14.8571H5.47826V9.28571H0L0 5.57143H5.47826ZM10.9565 16.7143V11.1429H16.4348V5.57143H29.2174L32.5591 9.28571H38.3478C40.3565 9.28571 42 10.9571 42 13V35.2857C42 37.3286 40.3565 39 38.3478 39H9.13043C7.12174 39 5.47826 37.3286 5.47826 35.2857V16.7143H10.9565ZM23.7391 33.4286C28.7791 33.4286 32.8696 29.2686 32.8696 24.1429C32.8696 19.0171 28.7791 14.8571 23.7391 14.8571C18.6991 14.8571 14.6087 19.0171 14.6087 24.1429C14.6087 29.2686 18.6991 33.4286 23.7391 33.4286ZM17.8957 24.1429C17.8957 27.43 20.507 30.0857 23.7391 30.0857C26.9713 30.0857 29.5826 27.43 29.5826 24.1429C29.5826 20.8557 26.9713 18.2 23.7391 18.2C20.507 18.2 17.8957 20.8557 17.8957 24.1429Z"
              fill="#1E355C"
            />
          </svg>
        </label>
        <div>
          {profilePicPreview ? <img src={profilePicPreview} alt="" /> : null}
        </div>
        {formErrors?.avatar || errors?.avatar ? (
          <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
            {formErrors?.avatar || errors?.avatar}
          </div>
        ) : null}
      </div>
      {formErrors?.error || errors?.error ? (
        <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
          {formErrors?.error || errors?.error}
        </div>
      ) : null}

      <div>
        <button
          type="button"
          onClick={() => {
            setFormErrors({});
            dispatch(
              PersonalInfoValidation(
                formErrors,
                setFormErrors,
                fullnames,
                phoneNumber,
                email,
                password,
                confirmPassword,
                profilePic,
                next
              )
            );
          }}
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-lightgreen hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightgreen"
        >
          next
        </button>
      </div>
    </form>
  );
  function validate() {}
}

export default PersonalInfo;
