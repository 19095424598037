import actionTypes from "../actions/types";

const initialState = {
  patients: [],
  fetchingPatients: false,
  fetchingPatient: false,
  selectedPatient: null,
  currentPatient: null,
  errors: null,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getPatients:
      return {
        ...state,
        errors: null,
        fetchingPatients: true,
      };
    case actionTypes.getPatientsSuccess:
      return {
        ...state,
        patients: action.payload,
        fetchingPatients: false,
      };
    case actionTypes.getPatientsFail:
      return {
        ...state,
        errors: action.payload,
        fetchingPatients: false,
      };
    case actionTypes.getPatient:
      return {
        ...state,
        errors: null,
        fetchingPatient: true,
      };
    case actionTypes.getPatientSuccess:
      return {
        ...state,
        patients: action.payload,
        fetchingPatient: false,
      };
    case actionTypes.getPatientFail:
      return {
        ...state,
        errors: action.payload,
        fetchingPatient: false,
      };

    case actionTypes.setSelectedPatients:
      return {
        ...state,
        selectedPatient: action.payload,
      };
    case actionTypes.setCurrentPatient:
      return {
        ...state,
        currentPatient: action.payload,
      };
    default:
      return state;
  }
};
