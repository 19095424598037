import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { renderRoutes } from "react-router-config";
import {
  getAdminFirebaseUser,
  getTotalUserUnreadMessages,
  markMessagesRead,
} from "../store/actions";
import Navbar from "./Navbar";
import Sidebar from "./Sidebar";

function DashboardLayout({ route }) {
  const { showSidebar } = useSelector(({ Style }) => Style);
  const { firebaseAdmin, openCharts, selectedChart } = useSelector(
    ({ Charts }) => Charts
  );
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getAdminFirebaseUser());
  }, []);
  useEffect(() => {
    if (firebaseAdmin) {
      dispatch(getTotalUserUnreadMessages());
    }
  }, [firebaseAdmin]);
  useEffect(() => {
    if (openCharts && selectedChart && firebaseAdmin) {
      dispatch(markMessagesRead());
    } else {
      console.log("chart is closed", openCharts);
    }
  }, [openCharts, selectedChart, firebaseAdmin]);
  return (
    <div className="flex ">
      <Sidebar />
      <div className="flex-1 w-100  ">
        <main className="bg-paleblue min-h-screen ">
          <Navbar />
          <div>{renderRoutes(route.routes)}</div>
        </main>
      </div>
    </div>
  );
}

export default DashboardLayout;
