export const provinces = [
  { id: 1, name: "Kigali" },
  { id: 2, name: "Southern Province" },
  { id: 3, name: "Nothern Province" },
  { id: 4, name: "Western Province" },
  { id: 5, name: "Eastern Province" },
];
export const districts = [
  { id: 1, name: "Gasabo" },
  { id: 2, name: "Kicukiro" },
  { id: 3, name: "Nyarugenge" },
  { id: 4, name: "Burera" },
  { id: 5, name: "Gakenke" },
  { id: 6, name: "Gicumbi" },
  { id: 7, name: "Musanze" },
  { id: 8, name: "Rulindo" },
  { id: 9, name: "Gisagara" },
  { id: 10, name: "Huye" },
  { id: 11, name: "Kamonyi" },
  { id: 12, name: "Muhanga" },
  { id: 13, name: "Nyamagabe" },
  { id: 14, name: "Nyanza" },
  { id: 15, name: "Nyaruguru" },
  { id: 16, name: "Ruhango" },
  { id: 17, name: "Bugesera" },
  { id: 18, name: "Gatsibo" },
  { id: 19, name: "Kayonza" },
  { id: 20, name: "Kirehe" },
  { id: 21, name: "Ngoma" },
  { id: 22, name: "Nyagatare" },
  { id: 23, name: "Rwamagana" },
  { id: 24, name: "Karongi" },
  { id: 25, name: "Ngororero" },
  { id: 26, name: "Nyabihu" },
  { id: 27, name: "Nyamasheke" },
  { id: 28, name: "Rubavu" },
  { id: 29, name: "Rusizi" },
  { id: 30, name: "Rutsiro" },
];
export const provinceDisctricts = [
  {
    id: 1,
    name: "Kigali",
    districts: [
      { id: 1, name: "Gasabo" },
      { id: 2, name: "Kicukiro" },
      { id: 3, name: "Nyarugenge" },
    ],
  },
  {
    id: 2,
    name: "Southern Province",
    districts: [
      { id: 9, name: "Gisagara" },
      { id: 10, name: "Huye" },
      { id: 11, name: "Kamonyi" },
      { id: 12, name: "Muhanga" },
      { id: 13, name: "Nyamagabe" },
      { id: 14, name: "Nyanza" },
      { id: 15, name: "Nyaruguru" },
      { id: 16, name: "Ruhango" },
    ],
  },
  {
    id: 3,
    name: "Nothern Province",
    districts: [
      { id: 4, name: "Burera" },
      { id: 5, name: "Gakenke" },
      { id: 6, name: "Gicumbi" },
      { id: 7, name: "Musanze" },
      { id: 8, name: "Rulindo" },
    ],
  },
  {
    id: 4,
    name: "Western Province",
    districts: [
      { id: 24, name: "Karongi" },
      { id: 25, name: "Ngororero" },
      { id: 26, name: "Nyabihu" },
      { id: 27, name: "Nyamasheke" },
      { id: 28, name: "Rubavu" },
      { id: 29, name: "Rusizi" },
      { id: 30, name: "Rutsiro" },
    ],
  },
  {
    id: 5,
    name: "Eastern Province",
    districts: [
      { id: 17, name: "Bugesera" },
      { id: 18, name: "Gatsibo" },
      { id: 19, name: "Kayonza" },
      { id: 20, name: "Kirehe" },
      { id: 21, name: "Ngoma" },
      { id: 22, name: "Nyagatare" },
      { id: 23, name: "Rwamagana" },
    ],
  },
];
