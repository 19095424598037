import React from "react";
import { BsExclamationLg } from "react-icons/bs";
function ApprovalNotification() {
  return (
    <div className="flex space-x-3 p-4 rounded-2xl bg-white">
      <div className="p-3 bg-yellow-500 rounded-circle flex items-center">
        {" "}
        <BsExclamationLg className="text-xl text-white" />
      </div>
      <p className="text-sm text-lightgreen">
        your account is being processed for approval
      </p>
    </div>
  );
}

export default ApprovalNotification;
