import actionTypes from "../actions/types";

const initialState = {
  rating: null,
  fetchingRating: false,
  errors: null,
  message: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getRating:
      return {
        ...state,
        errors: null,
        fetchingRating: true,
      };
    case actionTypes.getRatingSuccess:
      return {
        ...state,
        rating: action.payload,
        fetchingRating: false,
      };
    case actionTypes.getRatingFail:
      return {
        ...state,
        errors: action.payload,
        fetchingRating: false,
      };

    default:
      return state;
  }
};
