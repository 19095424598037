import React, { useEffect } from "react";
import Moment from "react-moment";
import userImg from "../../../assets/images/Care connect logo2.png";
function ReceivedConversation({ chart }) {
  return (
    <div className="grid grid-cols-3 ">
      <div className="md:space-x-2 space-y-2 md:space-y-0 col-span-2 flex ">
        <img
          src={userImg}
          alt="profile"
          className="w-16 h-16 rounded-circle border-lightgreen border-1"
        />

        <div className="flex flex-col text-gray-500">
          <p className="p-3 bg-paleblue rounded-xl text-sm w-48">
            {chart?.text}
          </p>

          <Moment className="text-xs justify-self-start" format="LLLL" fromNow>
            {chart.createdAt.toDate()}
          </Moment>
        </div>
      </div>
    </div>
  );
}

export default ReceivedConversation;
