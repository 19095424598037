// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore, collection } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// app's Firebase configuration

const firebaseConfig = {
  apiKey: "AIzaSyByX1XgPvbbd9rndsjnP5ATjJpoLMRwFIw",
  authDomain: "careconnect-46865.firebaseapp.com",
  projectId: "careconnect-46865",
  storageBucket: "careconnect-46865.appspot.com",
  messagingSenderId: "215528139404",
  appId: "1:215528139404:web:960ce069f397c5d45de9a9",
  measurementId: "G-YSZG880S2V",
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const colRef = collection(db, "chatRoom");
const storage = getStorage(app);

export { auth, db, storage, colRef };
