import { fetchRatingRequest } from "../../api";

import actionTypes from "./types";

export const getRating = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getRating });
    fetchRatingRequest()
      .then((res) => {
        if (res && res.data) {
          dispatch({
            type: actionTypes.getRatingSuccess,
            payload: res?.data,
          });
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getRatingFail, payload: err });
      });
  };
};
