import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../../components/registerSteps/Banner";
import Logo from "../../../assets/images/Care connect logo.png";
import { verifyEmail } from "../../../store/actions/userAuth.actions";
import { useHistory } from "react-router-dom";
function ResetPasswordRequest() {
  const { errors, loading, message } = useSelector(({ UserAuth }) => UserAuth);
  const [email, setEmail] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
      <div className="bg-darkwhite flex flex-col justify-center items-center px-10">
        <div className="max-w-md w-full space-y-8">
          <div className="flex flex-col justify-center items-center">
            <img src={Logo} alt="logo" className="w-8/12" />
            <h2 className="text-3xl mt-8 text-darkblue font-bold">
              Verify you Email
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            id="submit-login"
            onSubmit={onSubmit}
          >
            <p className="text-sm text-gray-600">
              Enter your email address below
            </p>
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-3">
              <div>
                <label htmlFor="email-address" className="sr-only">
                  Email address
                </label>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="appearance-none rounded-none relative block w-full px-3 py-2 placeholder-darkblue text-gray-900 rounded-t-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
                  placeholder="Email address"
                />
              </div>

              {errors && errors?.email ? (
                <div className="rounded border border-danger px-3 py-2 mt-1 mb-2 text-xs bg-red-100">
                  {errors?.email[0]}
                </div>
              ) : errors?.message ? (
                <div className="rounded border border-danger px-3 py-2 mt-1 mb-2 text-xs bg-red-100">
                  {errors?.message}
                </div>
              ) : null}
            </div>

            <div>
              <button
                type="submit"
                // onClick={() => history.push("/dashboard")}
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-lightgreen hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightgreen"
              >
                {loading ? "Sending..." : "Reset Password"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Banner />
    </div>
  );
  function onSubmit(e) {
    e.preventDefault();
    const data = { email: email };
    dispatch(verifyEmail(data, history));
  }
}

export default ResetPasswordRequest;
