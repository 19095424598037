import actionTypes from "../actions/types";

const initialState = {
  charts: [],
  usersList: [],
  latestMessages: [],
  isFetchingFirebaseAdminUser: false,
  isFetchingUsersList: false,
  isFetchingLatestMessages: false,
  isFetchingTotalUserUnreadMessages: false,
  isMarkingUnreanMessages: false,
  isFetching: false,
  isSending: false,
  isUpdating: false,
  errors: null,
  openCharts: false,
  selectedChart: [],
  firebaseAdmin: null,
  selectedUser: null,
  totalUserUnreadMessages: null,
  message: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.getAdminFirebaseUser:
      return {
        ...state,
        errors: null,
        isFetchingFirebaseAdminUser: true,
      };
    case actionTypes.getAdminFirebaseUserSuccess:
      return {
        ...state,
        firebaseAdmin: action.payload,
        isFetchingFirebaseAdminUser: false,
      };
    case actionTypes.getAdminFirebaseUserFail:
      return {
        ...state,
        isFetchingFirebaseAdminUser: false,
        errors: action.payload,
      };
    case actionTypes.getCharts:
      return {
        ...state,
        isFetching: true,
        errors: null,
      };
    case actionTypes.getChartsSuccess:
      return {
        ...state,
        charts: action.payload,
        isFetching: false,
      };
    case actionTypes.getChartsFail:
      return {
        ...state,
        isFetching: false,
        errors: action.payload,
      };
    case actionTypes.sendChart:
      return {
        ...state,
        isSending: true,
        errors: null,
      };
    case actionTypes.sendChartSuccess:
      return {
        ...state,
        isSending: false,
        message: action.payload,
      };
    case actionTypes.sendChartFail:
      return {
        ...state,
        isSending: false,
        errors: action.payload,
      };

    case actionTypes.getUsersList:
      return {
        ...state,
        isFetchingUsersList: true,
        errors: null,
      };
    case actionTypes.getUsersListSuccess:
      return {
        ...state,
        usersList: action.payload,
        isFetchingUsersList: false,
      };
    case actionTypes.getUsersListFail:
      return {
        ...state,
        isFetchingUsersList: false,
        errors: action.payload,
      };
    case actionTypes.getLatestMessages:
      return {
        ...state,
        isFetchingLatestMessages: true,
        errors: null,
      };
    case actionTypes.getLatestMessagesSuccess:
      return {
        ...state,
        latestMessages: action.payload,
        isFetchingLatestMessages: false,
      };
    case actionTypes.getLatestMessagesFail:
      return {
        ...state,
        isFetchingLatestMessages: false,
        errors: action.payload,
      };

    case actionTypes.getTotalUserUnreadMessages:
      return {
        ...state,
        isFetchingTotalUserUnreadMessages: true,
        errors: null,
      };
    case actionTypes.getTotalUserUnreadMessagesSuccess:
      return {
        ...state,
        totalUserUnreadMessages: action.payload,
        isFetchingTotalUserUnreadMessages: false,
      };
    case actionTypes.getTotalUserUnreadMessagesFail:
      return {
        ...state,
        isFetchingTotalUserUnreadMessages: false,
        errors: action.payload,
      };
    case actionTypes.markMessagesRead:
      return {
        ...state,
        isMarkingUnreanMessages: true,
        errors: null,
      };
    case actionTypes.markMessagesReadSuccess:
      return {
        ...state,
        isMarkingUnreanMessages: false,
      };
    case actionTypes.markMessagesReadFail:
      return {
        ...state,
        isMarkingUnreanMessages: false,
      };
    case actionTypes.setOpenCharts:
      return {
        ...state,
        openCharts: action.payload,
      };
    case actionTypes.setSelectedChart:
      return {
        ...state,
        selectedChart: action.payload,
      };
    case actionTypes.setSelectedUser:
      return {
        ...state,
        selectedUser: action.payload,
      };
    default:
      return state;
  }
};
