import { onAuthStateChanged } from "firebase/auth";
import React, { useEffect, useState } from "react";
import { ImSearch } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import Conversation from "../../components/chat/Conversation";

import MessagesList from "../../components/chat/MessagesList";
import Shimmers from "../../components/loaders/Shimmers";
import { auth, db } from "../../firebase";
import {
  getAdminFirebaseUser,
  getLatestMessages,
  getUsersList,
  onSetSelectedUser,
  setOpenCharts,
} from "../../store/actions";
function Charts() {
  const { userInfo } = useSelector(({ UserAuth }) => UserAuth);
  const {
    openCharts,
    charts,
    selectedChart,
    selectedUser,
    firebaseAdmin,
    usersList,
    latestMessages,
    isFetchingUsersList,
    isFetchingLatestMessages,
  } = useSelector(({ Charts }) => Charts);
  const [latestUnreadMessages, setLatestUnreadMessages] = useState([]);
  const dispatch = useDispatch();
  const getLatestUnreadMessages = () => {
    var latestUnreadMsgs = latestMessages.reduce((acc, data) => {
      if (data?.from === userInfo?.id || data?.to === userInfo?.id) {
        acc.push(data);
      }
      return acc;
    }, []);
    setLatestUnreadMessages(latestUnreadMsgs);
  };
  // useEffect(() => {
  //   setIsFetchingUser(true);
  //   const unsub = onAuthStateChanged(auth, (user) => setCurrentUser(user));
  //   setIsFetchingUser(false);
  //   return unsub();
  // }, []);
  useEffect(() => {
    dispatch(setOpenCharts(true));
    return () => {
      dispatch(setOpenCharts(false));
    };
  }, []);
  const unsub = useEffect(() => {
    if (openCharts) {
      dispatch(getAdminFirebaseUser());
      dispatch(getUsersList());
      dispatch(getLatestMessages());
    }
  }, [openCharts]);
  useEffect(() => {
    if (firebaseAdmin) {
      dispatch(onSetSelectedUser(firebaseAdmin));
    }
  }, [firebaseAdmin]);

  useEffect(() => {
    if (!isFetchingUsersList && !isFetchingLatestMessages) {
      getLatestUnreadMessages();
    }
  }, [isFetchingUsersList, isFetchingLatestMessages]);
  return (
    <div className="grid grid-cols-1 md:grid-cols-3 min-h-screen md:pt-14 pb-6 px-3.5 sm:px-10 gap-y-4 md:gap-x-4">
      {isFetchingUsersList || isFetchingLatestMessages ? (
        <div className="">
          <Shimmers width="100%" height="400px" />
        </div>
      ) : (
        <div className="space-y-6 h-96 lg:h-carditem lg:sticky lg:top-0  overflow-auto">
          {/* <div className="flex items-center space-x-6">
            <h5 className="text-darkblue font-bold text-lg">Inbox</h5>
            <p className="text-sm py-2 px-4 bg-lightgreen text-white rounded-xl">
              {latestUnreadMessages?.length}
            </p>
          </div> */}

          <MessagesList
            users={usersList}
            user={firebaseAdmin}
            currentUser={userInfo}
          />
        </div>
      )}
      {isFetchingUsersList || isFetchingLatestMessages ? (
        <div className="col-span-2">
          <Shimmers width="100%" height="400px" />
        </div>
      ) : (
        <div className="col-span-2">
          <Conversation currentUser={userInfo} />
        </div>
      )}
    </div>
  );
}

export default Charts;
