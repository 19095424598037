import React from "react";
import Logo from "../../../assets/images/Care connect logo.png";
import { RiCloseCircleFill } from "react-icons/ri";
import { useSelector } from "react-redux";
function ResetPasswordNotification() {
  const { errors, loading, message } = useSelector(({ UserAuth }) => UserAuth);
  return (
    <div className="h-screen flex justify-center items-center">
      <div className="flex flex-col justify-center items-center bg-white rounded-md space-y-6">
        <img src={Logo} alt="logo" className="w-8/12" />
        <h2 className="text-3xl  text-gray-500 font-bold">
          PLease check your email for a password reset link and click on to
          reset your recent password.
        </h2>
        <RiCloseCircleFill className="text-lightgreen text-3xl" />
      </div>
    </div>
  );
}

export default ResetPasswordNotification;
