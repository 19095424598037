import { async } from "@firebase/util";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db, storage } from "../../firebase";
import actionTypes from "./types";
export const getUsersList = (currentUser) => {
  return (dispatch, getState) => {
    const userInfo = getState().UserAuth.userInfo;
    dispatch({ type: actionTypes.getUsersList });
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "not-in", [userInfo?.id]));
    const unsubz = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      if (users?.length > 0) {
        users.map((user, index) => {
          if (user?.role === "Admin") {
            dispatch({ type: actionTypes.getUsersListSuccess, payload: users });
          }
        });
      } else {
        dispatch({ type: actionTypes.getUsersListSuccess, payload: null });
      }
    });
  };
};
export const getLatestMessages = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getLatestMessages });

    const lastMsgsRef = collection(db, "lastMsg");
    const q = query(lastMsgsRef);
    const messages = onSnapshot(q, (querySnapshot) => {
      let lastMsgs = [];
      querySnapshot.forEach((doc) => {
        lastMsgs.push(doc.data());
      });
      dispatch({
        type: actionTypes.getLatestMessagesSuccess,
        payload: lastMsgs,
      });
    });
  };
};
export const setOpenCharts = (state) => {
  return (dispatch, getState) => {
    const openCharts = getState().Charts.openCharts;
    dispatch({ type: actionTypes.setOpenCharts, payload: state });
  };
};
export const getMessages = (q, user) => (dispatch) => {
  return onSnapshot(q, (querySnapshot) => {
    let msgs = [];
    querySnapshot.forEach((doc) => {
      if (doc.data()?.from === user?.uid && doc.data()?.unread === true) {
        updateDoc(doc.ref, { unread: false });
      }
      msgs.push(doc.data());
    });
    dispatch({ type: actionTypes.setSelectedChart, payload: msgs });
  });
};

export const onSetSelectedUser = (user, currentUser) => {
  return async (dispatch, getState) => {
    const userInfo = getState().UserAuth.userInfo;

    dispatch({ type: actionTypes.setSelectedUser, payload: user });
    const user1 = currentUser?.id || userInfo?.id;
    const user2 = user.uid;
    const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;

    const msgsRef = collection(db, "chatRoom", id, "chat");
    const q = query(msgsRef, orderBy("createdAt", "asc"));
    onSnapshot(q, (querySnapshot) => {
      let msgs = [];
      querySnapshot.forEach((doc) => {
        // if (doc.data()?.from === user?.uid && doc.data()?.unread === true) {
        //   updateDoc(doc.ref, { unread: false });
        // }
        msgs.push(doc.data());
      });
      dispatch({ type: actionTypes.setSelectedChart, payload: msgs });
    });
    const docSnap = await getDoc(doc(db, "lastMsg", id));
    if (docSnap.data() && docSnap.data().from !== user1) {
      await updateDoc(doc(db, "lastMsg", id), { unread: false });
    }
  };
};

export const getAdminFirebaseUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.getAdminFirebaseUser });
    const userInfo = getState().UserAuth.userInfo;
    const usersRef = collection(db, "users");
    const q = query(usersRef, where("uid", "not-in", [userInfo?.id]));
    const unsubz = onSnapshot(q, (querySnapshot) => {
      let users = [];
      querySnapshot.forEach((doc) => {
        users.push(doc.data());
      });
      if (users?.length > 0) {
        users.map((user, index) => {
          if (user?.role === "Admin") {
            dispatch({
              type: actionTypes.getAdminFirebaseUserSuccess,
              payload: user,
            });
            dispatch({ type: actionTypes.setSelectedUser, payload: user });
          }
        });
      } else {
        dispatch({
          type: actionTypes.getAdminFirebaseUserFail,
          payload: null,
        });
      }
    });
  };
};
export const getTotalUserUnreadMessages = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.getTotalUserUnreadMessages });
    const userInfo = getState().UserAuth.userInfo;
    const firebaseAdmin = getState().Charts.firebaseAdmin;

    if (firebaseAdmin) {
      const user1 = firebaseAdmin?.uid;
      const user2 = userInfo?.id;
      const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
      const msgsRef = collection(db, "chatRoom", id, "chat");
      const q = query(msgsRef, orderBy("createdAt", "asc"));

      onSnapshot(q, (querySnapshot) => {
        let msgs = [];
        querySnapshot.forEach((doc) => {
          msgs.push(doc.data());
        });
        if (msgs?.length > 0) {
          var sum = 0;
          var totalNewMessages = msgs?.reduce((acc, data) => {
            if (data?.from === firebaseAdmin?.uid && data?.unread === true) {
              sum = sum + 1;
            }
            return sum;
          }, "");

          dispatch({
            type: actionTypes.getTotalUserUnreadMessagesSuccess,
            payload: sum,
          });
        } else {
          dispatch({
            type: actionTypes.getTotalUserUnreadMessagesFail,
            payload: null,
          });
        }
      });
    } else {
      dispatch({
        type: actionTypes.getTotalUserUnreadMessagesFail,
        payload: null,
      });
    }
  };
};
export const markMessagesRead = () => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.markMessagesRead });
    const userInfo = getState().UserAuth.userInfo;
    const firebaseAdmin = getState().Charts.firebaseAdmin;

    if (firebaseAdmin) {
      const user1 = firebaseAdmin?.uid;
      const user2 = userInfo?.id;
      const id = user1 > user2 ? `${user1 + user2}` : `${user2 + user1}`;
      const msgsRef = collection(db, "chatRoom", id, "chat");
      const q = query(msgsRef, orderBy("createdAt", "asc"));
      // const q = query(collection(db, "cities"), where("capital", "==", true));

      const querySnapshot = await getDocs(q);
      querySnapshot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots

        if (
          doc.data()?.from === firebaseAdmin?.uid &&
          doc.data()?.unread === true
        ) {
          updateDoc(doc.ref, { unread: false });
        }
      });
      dispatch({
        type: actionTypes.markMessagesReadSuccess,
        payload: null,
      });
      // get(q, (querySnapshot) => {
      //   let msgs = [];
      //   querySnapshot.forEach((doc) => {
      //     msgs.push(doc.data());
      //     if (
      //       doc.data()?.from === firebaseAdmin?.uid &&
      //       doc.data()?.unread === true
      //     ) {
      //       updateDoc(doc.ref, { unread: false });
      //     }
      //   });

      //   dispatch({
      //     type: actionTypes.markMessagesReadFail,
      //     payload: null,
      //   });
      // });
    } else {
      dispatch({
        type: actionTypes.markMessagesReadFail,
        payload: null,
      });
    }
  };
};
