import actionTypes from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const initialState = {
  userInfo: user?.user ?? null,
  loggedIn: user ? true : false,

  isRegistering: false,
  isFetchingUser: false,
  isUpdatingUser: false,
  loading: false,
  verifiedEmail: null,
  errors: null,
  message: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RegisterUser:
      return {
        ...state,
        isRegistering: true,
        errors: null,
      };
    case actionTypes.RegisterUserSuccess:
      return {
        ...state,
        userInfo: action.payload,
        loggedIn: true,
        isRegistering: false,
        errors: null,
      };

    case actionTypes.RegisterUserFail:
      return {
        ...state,
        errors: action.payload,
        isRegistering: false,
      };

    case actionTypes.LogUserIn:
      return {
        ...state,
        isFetchingUser: true,
        errors: null,
      };
    case actionTypes.LogUserInSuccess:
      return {
        ...state,
        userInfo: action.payload,
        loggedIn: true,
        isFetchingUser: false,
        errors: null,
      };

    case actionTypes.LogUserInFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingUser: false,
      };
    case actionTypes.LogUserOut:
      return {
        ...state,
        loggedIn: false,
        userInfo: null,
      };
    case actionTypes.getUser:
      return {
        ...state,
        isFetchingUser: true,
        errors: null,
      };
    case actionTypes.getUserSuccess:
      return {
        ...state,
        userInfo: action.payload,
        isFetchingUser: false,
        errors: null,
      };

    case actionTypes.getUserFail:
      return {
        ...state,
        errors: action.payload,
        isFetchingUser: false,
      };
    case actionTypes.updateUser:
      return {
        ...state,
        isUpdatingUser: true,
        errors: null,
      };
    case actionTypes.updateUserSuccess:
      return {
        ...state,
        userInfo: action.payload,
        isUpdatingUser: false,
        errors: null,
      };

    case actionTypes.updateUserFail:
      return {
        ...state,
        errors: action.payload,
        isUpdatingUser: false,
      };

    case actionTypes.verifyEmail:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.verifyEmailSuccess:
      return {
        ...state,
        message: action.payload,
        loading: false,
        errors: null,
      };

    case actionTypes.verifyEmailFail:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    case actionTypes.saveVerifiedEmail:
      return {
        ...state,
        verifiedEmail: action.payload,
      };
    case actionTypes.resetPassword:
      return {
        ...state,
        loading: true,
        errors: null,
      };
    case actionTypes.resetPasswordSuccess:
      return {
        ...state,
        message: action.payload,
        loading: false,
        errors: null,
      };

    case actionTypes.resetPasswordFail:
      return {
        ...state,
        errors: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
