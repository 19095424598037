import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Reviews from "../../components/summary/Reviews";
import Schedule from "../../components/summary/Schedule";
import SummaryBanner from "../../components/summary/SummaryBanner";
import ApprovalNotification from "../../components/summary/ApprovalNotification";
import { useHistory } from "react-router-dom";
import { useAlert } from "react-alert";
import { getProfile } from "../../store/actions";
import LatestPatient from "../../components/summary/LatestPatient";
function Summary() {
  const { userInfo } = useSelector(({ UserAuth }) => UserAuth);
  const { profile } = useSelector(({ Profile }) => Profile);
  const dispatch = useDispatch();
  const history = useHistory();
  const alert = useAlert();
  useEffect(() => {
    dispatch(getProfile(history, alert));
  }, []);
  return (
    <div className="grid grid-cols-1 lg:grid-cols-3 min-h-screen">
      <div className="col-span-2 md:pt-20 pb-6 space-y-10 mx-3.5 sm:mx-10">
        <div>
          <h5 className="text-lg text-gray-500">Hi {userInfo?.full_names}</h5>
          <h2 className="text-darkblue text-4xl font-bold">Welcome Back!</h2>
          <div className="lg:hidden pt-2 lg:pt-0">
            {" "}
            {profile?.status === "0" ? <ApprovalNotification /> : null}
          </div>
        </div>
        <SummaryBanner />
        <LatestPatient />
      </div>
      <div className="bg-paleindigo md:pt-20 pb-6 space-y-10 px-3.5 sm:px-10">
        <h5 className="text-darkblue text-xl font-bold">My Schedule</h5>
        <div className="hidden lg:block ">
          {" "}
          {profile?.status === "0" ? <ApprovalNotification /> : null}
        </div>
        <Schedule />
        <Reviews />
      </div>
    </div>
  );
}

export default Summary;
