import React from "react";
import { Redirect } from "react-router-dom";
import BlankLayout from "../layout/BlankLayout";
import DashboardLayout from "../layout/DashboardLayout";
import ResetPasswordRequest from "../pages/auth/password reset";
import ResetPasswordForm from "../pages/auth/password reset/ResetPasswordForm";
import ResetPasswordNotification from "../pages/auth/password reset/ResetPasswordNotification";
import SignIn from "../pages/auth/SignIn";
import SignUp from "../pages/auth/SignUp";
import Charts from "../pages/charts";
import CurrentPatientDetails from "../pages/patients/CurrentPatientDetails";
import Profile from "../pages/profile";
import CompleteProfile from "../pages/profile/CompleteProfile";
import Reports from "../pages/reports";
import Summary from "../pages/Summary";

import {
  requiresAuthentication,
  requiresNoAuthentication,
} from "../store/middleware";

const routes = [
  {
    component: BlankLayout,

    routes: [
      {
        name: "Index",
        path: "/",
        exact: true,
        render: () => <Redirect to="/signin" />,
      },
      {
        name: "Dashboard",
        path: "/dashboard",
        component: requiresAuthentication(DashboardLayout),
        routes: [
          {
            name: "Dashboard Home",
            path: "/dashboard",
            exact: true,
            component: Summary,
          },
          {
            name: "Patient page",
            path: "/dashboard/patient",
            exact: true,
            component: CurrentPatientDetails,
          },
          {
            name: "Charts page",
            path: "/dashboard/charts",
            exact: true,
            component: Charts,
          },
          {
            name: "Reports page",
            path: "/dashboard/reports",
            exact: true,
            component: Reports,
          },
          {
            name: "Profile page",
            path: "/dashboard/profile",
            exact: true,
            component: Profile,
          },
          {
            name: "Complete Profile ",
            path: "/dashboard/completeprofile",
            exact: true,
            component: CompleteProfile,
          },
        ],
      },
      {
        name: "Sign up page",
        path: "/signup",

        exact: true,
        component: requiresNoAuthentication(SignUp),
      },

      {
        name: "Sign in page",
        path: "/signin",
        exact: true,
        component: requiresNoAuthentication(SignIn),
      },
      {
        name: "Reset password request page",
        path: "/account/password/reset",

        exact: true,
        component: requiresNoAuthentication(ResetPasswordRequest),
      },
      {
        name: "Reset password Notification page",
        path: "/account/password/reset/success",

        exact: true,
        component: requiresNoAuthentication(ResetPasswordNotification),
      },
      {
        name: "Reset password Form",
        path: "/account/password/reset/edit",

        exact: true,
        component: requiresNoAuthentication(ResetPasswordForm),
      },
    ],
  },
];
export default routes;
