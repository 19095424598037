import React, { useEffect } from "react";
import { useState } from "react";
import userImg from "../../assets/images/nurse 2.png";
import { FaCamera } from "react-icons/fa";
import { HiPencil } from "react-icons/hi";
import { IoCloseCircle } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { GetValidImage } from "../../utils/image";
import { updateUser } from "../../store/actions";
import { useAlert } from "react-alert";
import Shimmers from "../loaders/Shimmers";
import { Form } from "react-bootstrap";
import { districts, provinceDisctricts, provinces } from "../../utils/data";
function AccountDetails({ editProfile, setEditProfile, activePane }) {
  const { userInfo, isUpdatingUser, isFetchingUser } = useSelector(
    ({ UserAuth }) => UserAuth
  );
  const [profile, setProfile] = useState(null);
  const [profilePreview, setProfilePreview] = useState(null);
  const [accountValues, setAccountValues] = useState({
    address: null,
    avatar: null,
    date_of_birth: null,
    email: null,
    full_names: null,
    gender: null,
    phone_number: null,
  });
  const [province, setProvince] = useState(null);
  const [districtsList, setDistrictsList] = useState([]);
  const [district, setDistrict] = useState(null);
  const [editFullNames, setEditFullNames] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAddres, setEditAddress] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  useEffect(() => {
    if (!isFetchingUser && userInfo) {
      setAccountValues({
        address: userInfo?.address,
        avatar: userInfo?.avatar,
        date_of_birth: userInfo?.date_of_birth,
        email: userInfo?.email,
        full_names: userInfo?.full_names,
        gender: userInfo?.gender,
        phone_number: userInfo?.phone_number,
      });
    }
  }, [isFetchingUser, userInfo]);
  useEffect(() => {
    if (province) {
      const districts = provinceDisctricts.find(
        (province_) => province_.name === province
      );
      if (!typeof districts !== "undefined") {
        setDistrictsList(districts);
      }
    }
  }, [province]);
  useEffect(() => {
    if (district) {
      setAccountValues({
        ...accountValues,
        address: " " + district + ", " + province + ", Rwanda",
      });
    }
  }, [district]);
  useEffect(() => {
    if (editProfile && activePane === "account-details") {
      onUpdate();
    }
  }, [editProfile]);
  return (
    <div className=" bg-white px-6 sm:p-10 space-y-3 rounded-xl">
      <div className="flex  justify-center items-start py-2">
        {isFetchingUser ? (
          <div className="overflow-hidden rounded-circle">
            <Shimmers width="150px" height="150px" />
          </div>
        ) : (
          <div className="relative">
            <div className="w-40 h-40 rounded-circle border-4 ">
              <img
                className="w-40 h-40 rounded-circle relative border-4"
                src={
                  profilePreview
                    ? profilePreview
                    : GetValidImage(accountValues?.avatar)
                }
              />
            </div>

            <input
              type="file"
              accept="image/*"
              id="user-avatar"
              onChange={(e) => {
                setProfile(e.target.files[0]);
                setProfilePreview(URL.createObjectURL(e.target.files[0]));
              }}
              hidden
            />
            <label
              for="user-avatar"
              className="cursor-pointer absolute m-0 right-16 -bottom-2 rounded-circle "
            >
              {" "}
              <FaCamera className="text-lightgreen text-4xl" />
            </label>
          </div>
        )}
      </div>

      <div className="space-y-3">
        <div className=" sm:flex sm:space-x-3 items-center">
          <label
            className="text-sm text-gray-600 font-bold w-32"
            htmlFor="names"
          >
            Full Names
          </label>
          {isFetchingUser ? (
            <div className="overflow-hidden">
              <Shimmers width="600px" height="50px" className="w-100" />
            </div>
          ) : (
            <div className="flex items-center flex-1">
              <input
                disabled={editFullNames ? false : true}
                type="text"
                className="flex-1 w-100 text-gray-500  text-sm h-100 form-control px-4 py-2 focus:shadow-none focus:ring-lightgreen focus:border-lightgreen focus:z-10"
                id="names"
                value={accountValues?.full_names}
                onChange={(e) =>
                  setAccountValues({
                    ...accountValues,
                    full_names: e.target.value,
                  })
                }
                placeholder="Enter you full names"
              />
              {editFullNames ? (
                <IoCloseCircle
                  className="text-xl text-red-600"
                  role="button"
                  onClick={() => {
                    setEditFullNames(false);
                    setAccountValues({
                      ...accountValues,
                      full_names: userInfo?.full_names,
                    });
                  }}
                />
              ) : (
                <HiPencil
                  className="text-lightgreen text-xl"
                  role="button"
                  onClick={() => setEditFullNames(true)}
                />
              )}
            </div>
          )}
        </div>
        {/* <div className="flex items-center justify-center">
          <hr className="w-5/6 text-gray-700" />
        </div> */}
        <div className=" sm:flex sm:space-x-3 items-center">
          <label
            className="text-sm text-gray-600 font-bold w-32"
            htmlFor="email"
          >
            Email
          </label>
          {isFetchingUser ? (
            <div className="overflow-hidden">
              <Shimmers width="600px" height="50px" className="w-100" />
            </div>
          ) : (
            <div className="flex items-center flex-1">
              <input
                disabled={editEmail ? false : true}
                type="text"
                className="w-100 text-gray-500  text-sm h-100 form-control px-4 py-2 focus:shadow-none focus:ring-lightgreen focus:border-lightgreen focus:z-10"
                name="email"
                id="email"
                value={accountValues?.email}
                onChange={(e) =>
                  setAccountValues({ ...accountValues, email: e.target.value })
                }
                placeholder="Enter your email"
              />
              {editEmail ? (
                <IoCloseCircle
                  className="text-xl text-red-600"
                  role="button"
                  onClick={() => {
                    setEditEmail(false);
                    setAccountValues({
                      ...accountValues,
                      email: userInfo?.email,
                    });
                  }}
                />
              ) : (
                <HiPencil
                  className="text-lightgreen text-xl"
                  role="button"
                  onClick={() => setEditEmail(true)}
                />
              )}
            </div>
          )}
        </div>

        {/* <div className="flex items-center justify-center">
          <hr className="w-5/6 text-gray-700" />
        </div> */}
        <div className="sm:flex sm:space-x-3 items-center">
          <label
            className="text-sm text-gray-600 font-bold w-32"
            htmlFor="phone"
          >
            Phone
          </label>
          {isFetchingUser ? (
            <div className="overflow-hidden">
              <Shimmers width="600px" height="50px" className="w-100" />
            </div>
          ) : (
            <div className="flex items-center flex-1">
              <input
                disabled={editPhone ? false : true}
                type="text"
                className="w-100 text-gray-500  text-sm h-100 form-control px-4 py-2 focus:shadow-none focus:ring-lightgreen focus:border-lightgreen focus:z-10"
                name="phone"
                id="phone_number"
                value={accountValues?.phone_number}
                onChange={(e) =>
                  setAccountValues({
                    ...accountValues,
                    phone_number: e.target.value,
                  })
                }
                placeholder="Enter your phone number"
              />{" "}
              {editPhone ? (
                <IoCloseCircle
                  className="text-xl text-red-600"
                  role="button"
                  onClick={() => {
                    setEditPhone(false);
                    setAccountValues({
                      ...accountValues,
                      phone_number: userInfo?.phone_number,
                    });
                  }}
                />
              ) : (
                <HiPencil
                  className="text-lightgreen text-xl"
                  role="button"
                  onClick={() => setEditPhone(true)}
                />
              )}
            </div>
          )}
        </div>
        {/* <div className="flex items-center justify-center">
          <hr className="w-5/6 text-gray-700" />
        </div> */}
        <div className="sm:flex sm:space-x-3 items-center">
          <label
            className="text-sm text-gray-600 font-bold w-32"
            htmlFor="address"
          >
            Address
          </label>
          {isFetchingUser ? (
            <div className="overflow-hidden">
              <Shimmers width="600px" height="50px" className="w-100" />
            </div>
          ) : (
            <div className="flex items-center flex-1">
              {editAddres ? (
                <div className="grid grid-cols-1 lg:grid-cols-2 gap-2 flex-1">
                  <div>
                    <label htmlFor="address-province" className="sr-only">
                      Province
                    </label>
                    <Form.Select
                      id="address-province"
                      onChange={(e) => setProvince(e.target.value)}
                      required
                      className="text-xs border-0 py-3 appearance-none focus:outline-none focus:ring-darkblue focus:border-darkblue focus:z-10"
                    >
                      <option
                        className="text-xs text-lightgray"
                        value=" "
                        selected
                        disabled
                      >
                        Select Province
                      </option>
                      {provinces?.map((province, index) => (
                        <option
                          className="text-xs text-lightgray"
                          key={index}
                          value={province?.name ?? " "}
                        >
                          {province.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                  {province ? (
                    <div>
                      <label htmlFor="address-district" className="sr-only">
                        District
                      </label>
                      <Form.Select
                        id="address-district"
                        onChange={(e) => setDistrict(e.target.value)}
                        required
                        className="text-xs border-0 py-3 appearance-none focus:outline-none focus:ring-darkblue focus:border-darkblue focus:z-10"
                      >
                        <option
                          className="text-xs text-lightgray"
                          selected
                          disabled
                        >
                          Select District
                        </option>
                        {districtsList?.districts?.map((district, index) => (
                          <option
                            className="text-xs text-lightgray"
                            key={index}
                            value={district?.name ?? " "}
                          >
                            {district.name}
                          </option>
                        ))}
                      </Form.Select>
                    </div>
                  ) : null}
                </div>
              ) : (
                <input
                  disabled={editAddres ? false : true}
                  type="text"
                  className="w-100 text-gray-500 text-sm h-100 form-control px-4 py-2 focus:shadow-none focus:ring-lightgreen focus:border-lightgreen focus:z-10"
                  name="address"
                  id="address"
                  value={accountValues?.address}
                  onChange={(e) =>
                    setAccountValues({
                      ...accountValues,
                      address: e.target.value,
                    })
                  }
                  placeholder="Enter your address"
                />
              )}
              {editAddres ? (
                <IoCloseCircle
                  className="text-xl text-red-600"
                  role="button"
                  onClick={() => setEditAddress(false)}
                />
              ) : (
                <HiPencil
                  className="text-lightgreen text-xl"
                  role="button"
                  onClick={() => setEditAddress(true)}
                />
              )}
            </div>
          )}
        </div>
        {/* <div className="flex items-center justify-center">
          <hr className="w-5/6 text-gray-700" />
        </div> */}
      </div>
    </div>
  );
  function onUpdate() {
    if (isUpdatingUser) return;

    var formData = new FormData();
    formData.append("full_names", accountValues?.full_names);
    if (userInfo?.email !== accountValues?.email) {
      formData.append("email", accountValues?.email);
    }

    formData.append("phone_number", accountValues?.phone_number);
    // formData.append("gender", gender);
    // formData.append("date_of_birth", age);
    formData.append("address", accountValues?.address);
    if (profile) {
      formData.append("avatar", profile);
    }

    dispatch(updateUser(formData, setEditProfile, activePane, alert));
  }
}

export default AccountDetails;
