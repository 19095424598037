import {
  fetchPatientsRequest,
  fetchSinglePatientRequest,
  fetchSubscriptionsRequest,
} from "../../api";
import actionTypes from "./types";

export const getPatients = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getPatients });
    fetchPatientsRequest()
      .then((res) => {
        dispatch({
          type: actionTypes.getPatientsSuccess,
          payload: res?.data,
        });
        if (res?.data && res?.data[0]) {
          dispatch(onSetCurrentPatient(res?.data[0]));
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getPatientsFail });
      });
  };
};
export const getSinglePatient = (patientId) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getPatient });
    fetchSinglePatientRequest(patientId)
      .then((res) => {
        dispatch({
          type: actionTypes.getPatientSuccess,
          payload: res?.data,
        });
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getPatientFail });
      });
  };
};
export const onSetSelectedPatient = (patient) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.setSelectedPatients,
      payload: patient,
    });
  };
};
export const onSetCurrentPatient = (patient) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.setCurrentPatient,
      payload: patient,
    });
  };
};
