import React from "react";
import nurseImg from "../../assets/images/nurse-removebg.png";
function SummaryBanner() {
  return (
    <div className="bg-lightgreen summary-banner rounded-2xl  relative text-white">
      <div className=" grid grid-cols-2 top-0 h-100">
        <div className="m-10 ">
          <p className="text-xs">Daily Reminder</p>
          <h5 className="font-bold text-2xl sm:text-4xl  mt-6 sm:mt-8 sm:mb-10">
            Your Focus Determines Your Reality!
          </h5>
        </div>
        <div className="relative">
          <img src={nurseImg} alt="Nurse" className="absolute bottom-0" />
        </div>
      </div>
    </div>
  );
}

export default SummaryBanner;
