import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import ReportForm from "../../components/report/ReportForm";
import ReportsList from "../../components/report/ReportsList";
import { getProfile } from "../../store/actions";

function Reports() {
  const alert = useAlert();
  const dispatch = useDispatch();
  const history = useHistory();
  useEffect(() => {
    dispatch(getProfile(history, alert));
  }, []);
  return (
    <div className="min-h-screen  grid grid-cols-1 md:grid-cols-3 md:gap-4">
      <ReportForm />
      <ReportsList />
    </div>
  );
}

export default Reports;
