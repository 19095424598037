import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { registerUser } from "../../store/actions";
import { districts, provinceDisctricts, provinces } from "../../utils/data";

function AddressInfo({
  fullnames,
  phoneNumber,
  email,
  password,
  confirmPassword,
  profilePic,
  setAddress,
  setAge,
  setGender,
  address,
  age,
  gender,
  prev,
}) {
  const { errors, isRegistering } = useSelector(({ UserAuth }) => UserAuth);
  const history = useHistory();
  const dispatch = useDispatch();
  const [province, setProvince] = useState(null);
  const [district, setDistrict] = useState(null);
  const [addressError, setAddressError] = useState(null);
  const [districtsList, setDistrictsList] = useState([]);
  useEffect(() => {
    if (province) {
      const districts = provinceDisctricts.find(
        (province_) => province_.name === province
      );
      if (!typeof districts !== "undefined") {
        setDistrictsList(districts);
      }
    }
  }, [province]);
  return (
    <form className="mt-8 space-y-6" id="submit-login" onSubmit={onSubmit}>
      <input type="hidden" name="remember" defaultValue="true" />
      <div className="space-y-6">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div>
            <label htmlFor="address-province" className="sr-only">
              Province
            </label>
            <Form.Select
              id="address-province"
              onChange={(e) => setProvince(e.target.value)}
              required
              className="text-xs border-0 py-3 appearance-none focus:outline-none focus:ring-darkblue focus:border-darkblue focus:z-10"
            >
              <option
                className="text-xs text-lightgray"
                value=" "
                selected
                disabled
              >
                Select Province
              </option>
              {provinces?.map((province, index) => (
                <option
                  className="text-xs text-lightgray"
                  key={index}
                  value={province?.name ?? " "}
                >
                  {province.name}
                </option>
              ))}
            </Form.Select>
          </div>
          <div>
            <label htmlFor="address-district" className="sr-only">
              District
            </label>

            <Form.Select
              id="address-district"
              onChange={(e) => setDistrict(e.target.value)}
              required
              className="text-xs border-0 py-3 appearance-none focus:outline-none focus:ring-darkblue focus:border-darkblue focus:z-10"
            >
              <option className="text-xs text-lightgray" selected disabled>
                Select District
              </option>
              {province
                ? districtsList?.districts?.map((district, index) => (
                    <option
                      className="text-xs text-lightgray"
                      key={index}
                      value={district?.name ?? " "}
                    >
                      {district.name}
                    </option>
                  ))
                : districts?.map((district, index) => (
                    <option
                      className="text-xs text-lightgray"
                      key={index}
                      value={district?.name ?? " "}
                    >
                      {district.name}
                    </option>
                  ))}
            </Form.Select>
          </div>
        </div>
        {(errors && errors?.address) || addressError ? (
          <div className="rounded border border-danger px-3 py-2 mt-1 mb-2 text-xs bg-red-100">
            {(errors?.address[0] || addressError) ?? " "}
          </div>
        ) : null}
        {/* <div>
          <label htmlFor="address" className="sr-only">
            Address
          </label>
          <input
            id="address"
            name="address"
            type="text"
            autoComplete="address"
            value={address}
            onChange={(e) => setAddress(e.target.value)}
            required
            className="appearance-none rounded-none relative block w-full px-3 py-3 placeholder-darkblue text-darkblue rounded-t-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
            placeholder="Enter Your Address (eg: kacyiru, kigali, Rwanda)"
          />
          {errors && errors?.address ? (
            <div className="rounded border border-danger px-3 py-2 mt-1 mb-2 text-xs bg-red-100">
              {errors?.address[0]}
            </div>
          ) : null}
        </div> */}
        <div className="sm:flex items-center bg-white">
          <label htmlFor="age" className="mx-3 text-darkblue text-sm">
            Date of birth
          </label>
          <input
            id="age"
            name="age"
            type="date"
            autoComplete="age"
            required
            value={age}
            onChange={(e) => setAge(e.target.value)}
            className="appearance-none rounded-none relative block w-full px-3 py-3 placeholder-darkblue text-darkblue rounded-b-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
            placeholder="Age"
            aria-placeholder="Age"
          />
          {errors && errors?.age ? (
            <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
              {errors?.age[0]}
            </div>
          ) : null}
        </div>
        <div className="sm:flex  bg-white space-y-2 sm:space-y-0">
          <p className="mx-3 text-darkblue text-sm">Gender</p>
          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="male"
              value="Male"
              selected={gender === "Male" ? true : false}
              required
              onChange={(e) => setGender(e.target.value)}
            />
            <label
              className="form-check-label text-sm text-darkblue"
              for="male"
            >
              Male
            </label>
          </div>

          <div className="form-check mx-2">
            <input
              className="form-check-input"
              type="radio"
              name="gender"
              id="female"
              value="Female"
              selected={gender === "Female" ? true : false}
              required
              onChange={(e) => setGender(e.target.value)}
            />
            <label
              className="form-check-label text-sm text-darkblue"
              for="female"
            >
              Female
            </label>
          </div>
        </div>
        {errors && errors?.gender ? (
          <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
            {errors?.gender[0]}
          </div>
        ) : null}
      </div>
      {errors?.error ? (
        <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
          {errors?.error}
        </div>
      ) : null}

      <div>
        <button
          type="submit"
          // onClick={() => history.push("/dashboard/completeprofile")}
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-lightgreen hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightgreen"
        >
          {isRegistering ? "Registering..." : "Register"}
        </button>
      </div>
    </form>
  );
  function onSubmit(e) {
    e.preventDefault();
    if (isRegistering) return;
    setAddressError(null);
    if (!district || !province) {
      setAddressError("Please Fill you address !");
    }
    const fullAddress = " " + district + ", " + province + ", Rwanda";
    var formData = new FormData();
    formData.append("full_names", fullnames);
    formData.append("email", email);
    formData.append("phone_number", phoneNumber);
    formData.append("password", password);
    formData.append("password_confirmation", confirmPassword);
    formData.append("gender", gender);
    formData.append("date_of_birth", age);
    formData.append("address", fullAddress);
    formData.append("avatar", profilePic);

    dispatch(registerUser(formData, history, prev));
  }
}

export default AddressInfo;
