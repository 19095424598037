import React, { useEffect, useState } from "react";
import DayPicker from "react-day-picker";
import "react-day-picker/lib/style.css";
import { useSelector } from "react-redux";
import Shimmers from "../loaders/Shimmers";
import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
function Schedule() {
  const { fetchingPatients, patients, currentPatient } = useSelector(
    ({ Patients }) => Patients
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const getDy = () => {
    let expirationDate = moment(currentPatient?.start_date)
      .add(currentPatient?.duration - 1, "d")
      .format("YYYY-MM-DD");
    const tnewDt = new Date(expirationDate);
    const startDa = new Date(currentPatient?.start_date);
    setStartDate(startDa);
    setEndDate(tnewDt);
    return tnewDt;
  };
  useEffect(() => {
    if (currentPatient) {
      getDy();
    }
  }, [currentPatient]);
  return (
    <div className="flex">
      <div className="flex-1 overflow-auto flex justify-center rounded-2xl datePicker">
        {fetchingPatients ? (
          <div className="overflow-hidden">
            <Shimmers width="600px" height="200px" />
          </div>
        ) : (
          // <DayPicker
          //   selectedDays={[
          //     // new Date(2022, 3, 21),
          //     // new Date(2022, 3, 24),
          //     startDate,
          //     endDate,
          //     {
          //       after: endDate,
          //       before: startDate,
          //     },
          //     // {
          //     //   after: new Date(2022, 3, 20),
          //     //   before: new Date(2022, 3, 24),
          //     // },
          //   ]}
          // />
          <DatePicker
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            selectsRange={true}
            inline
            readOnly={true}
          />
        )}
      </div>
    </div>
  );
}

export default Schedule;
