const actionTypes = {
  //AUNTHENTICATIONS
  RegisterUser: "REGISTER USER IN",
  RegisterUserSuccess: "REGISTER USER IN SUCCESSFUL",
  RegisterUserFail: "REGISTER USER IN FAILED",

  getUser: "GET USER",
  getUserSuccess: "GET USER SUCCESSFUL",
  getUserFail: "GET USER FAILED",

  updateUser: "UPDATE USER",
  updateUserSuccess: "UPDATE USER SUCCESSFUL",
  updateUserFail: "UPDATE USER FAILED",

  LogUserIn: "LOG USER IN",
  LogUserInSuccess: "LOG USER IN SUCCESSFUL",
  LogUserInFail: "LOG USER IN FAILED",

  LogUserOut: "LOG USER OUT",

  verifyEmail: "VERIFY EMAIL ",
  verifyEmailSuccess: "VERIFY EMAIL SUCCESSFUL",
  verifyEmailFail: "VERIFY EMAIL FAILED",

  saveVerifiedEmail: "SAVE VERIFIED EMAIL",

  resetPassword: "RESET PASSWORDT",
  resetPasswordSuccess: "RESET PASSWORD SUCCESSFUL",
  resetPasswordFail: "RESET PASSWORD FAILED",
  //Profile
  createProfile: "CREATE PROFILE",
  createProfileSuccess: "CREATE PROFILE SUCCESSFUL",
  createProfileFail: "CREATE PROFILE FAILED",

  getProfile: "GET PROFILE",
  getProfileSuccess: "GET PROFILE SUCCESSFUL",
  getProfileFail: "GET PROFILE FAILED",

  updateProfile: "UPDATE PROFILE",
  updateProfileSuccess: "UPDATE PROFILE SUCCESSFUL",
  updateProfileFail: "UPDATE PROFILE FAILED",
  //Availability
  createAvailability: "CREATE AVAILABILITY",
  createAvailabilitySuccess: "CREATE AVAILABILITY SUCCESSFUL",
  createAvailabilityFail: "CREATE AVAILABILITY FAILED",

  getAvailability: "GET AVAILABILITY",
  getAvailabilitySuccess: "GET AVAILABILITY SUCCESSFUL",
  getAvailabilityFail: "GET AVAILABILITY FAILED",

  updateAvailability: "UPDATE AVAILABILITY",
  updateAvailabilitySuccess: "UPDATE AVAILABILITY SUCCESSFUL",
  updateAvailabilityFail: "UPDATE AVAILABILITYE FAILED",
  //Subscriptions
  getSubscriptions: "GET SUBSCRIPTIONS",
  getSubscriptionsSuccess: "GET SUBSCRIPTIONS SUCCESSFUL",
  getSubscriptionsFail: "GET SUBSCRIPTIONS FAILED",

  setSelectedSubscriptions: "SET SELECTED SUBSCRIPTIONS",

  //Patients
  getPatients: "GET PATIENTS",
  getPatientsSuccess: "GET PATIENTS SUCCESSFUL",
  getPatientsFail: "GET PATIENTS FAILED",

  getPatient: "GET PATIENT",
  getPatientSuccess: "GET PATIENT SUCCESSFUL",
  getPatientFail: "GET PATIENT FAILED",

  setSelectedPatients: "SET SELECTED PATIENTS",
  setCurrentPatient: "SET CURRENT PATIENT",
  //Charts
  getCharts: "GET CHARTS USER",
  getChartsSuccess: "GET CHARTS SUCCESSFUL",
  getChartsFail: "GET CHARTS FAILED",

  getAdminFirebaseUser: "GET ADMIN FIREBASE USER",
  getAdminFirebaseUserSuccess: "GET ADMIN FIREBASE USER SUCCESSFUL",
  getAdminFirebaseUserFail: "GET ADMIN FIREBASE USER FAILED",

  sendChart: "SEND CHART USER",
  sendChartSuccess: "SEND CHART SUCCESSFUL",
  sendChartFail: "SEND CHART FAILED",

  getUsersList: "GET USERS LIST",
  getUsersListSuccess: "GET USERS LIST SUCCESSFUL",
  getUsersListFail: "GET USERS LIST FAILED",

  getLatestMessages: "GET LATEST MESSAGES",
  getLatestMessagesSuccess: "GET LATEST MESSAGES SUCCESSFUL",
  getLatestMessagesFail: "GET LATEST MESSAGES FAILED",

  getTotalUserUnreadMessages: "GET TOTAL USER UNREAD MESSAGES",
  getTotalUserUnreadMessagesSuccess:
    "GET TOTAL USER UNREAD MESSAGES SUCCESSFUL",
  getTotalUserUnreadMessagesFail: "GET TOTAL USER UNREAD MESSAGES FAILED",

  markMessagesRead: "MARK MESSAGES READ",
  markMessagesReadSuccess: "MARK MESSAGES READ SUCCESSFUL",
  markMessagesReadFail: "MARK MESSAGES READ FAILED",

  setOpenCharts: "SET OPEN CHARTS",
  setSelectedChart: "SET SELECTED CHART",
  setSelectedUser: "SET SELECTED USER",
  //Report
  getReport: "GET REPORT",
  getReportSuccess: "GET REPORT SUCCESSFUL",
  getReportFail: "GET REPORT FAILED",

  sendReport: "SEND REPORT",
  sendReportSuccess: "SEND REPORT SUCCESSFUL",
  sendReportFail: "SEND REPORT FAILED",

  setSelectedReport: "SET SELECTED REPORT",
  //style
  setShowSideBar: "SET SHOW SIDEBAR",

  //Rating
  getRating: "GET RATING",
  getRatingSuccess: "GET RATING SUCCESSFUL",
  getRatingFail: "GET RATING FAILED",
};

export default actionTypes;
