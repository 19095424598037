import { combineReducers } from "redux";
import chartsReducers from "./charts.reducers";
import userAuthReducers from "./userAuth.reducers";
import profileReducers from "./profile.reducers";
import subscriptionsReducers from "./subscriptions.reducers";
import reportReducers from "./report.reducers";
import patientReducers from "./patient.reducers";
import stylesReducers from "./styles.reducers";
import ratingReducers from "./rating.reducers";
export default combineReducers({
  UserAuth: userAuthReducers,
  Profile: profileReducers,
  Charts: chartsReducers,
  Subscriptions: subscriptionsReducers,
  Reports: reportReducers,
  Patients: patientReducers,
  Style: stylesReducers,
  Ratings: ratingReducers,
});
