import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import { AiFillStar } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getProfile, getRating } from "../../store/actions";
import Shimmers from "../loaders/Shimmers";

function Reviews() {
  const { fetchingRating, rating } = useSelector(({ Ratings }) => Ratings);
  const { fetchingProfile, profile } = useSelector(({ Profile }) => Profile);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  useEffect(() => {
    dispatch(getRating());
    dispatch(getProfile(history, alert));
  }, []);
  return fetchingRating || fetchingProfile ? (
    <div>
      <Shimmers width="100%" height="200px" />
    </div>
  ) : rating ? (
    <div className="bg-white rounded-2xl p-6 space-y-4">
      <div className="rounded-fill bg-paleblue flex justify-center items-center flex-wrap space-x-3 py-2 rounded-2xl">
        <div className="flex space-x-3">
          <AiFillStar className="text-yellow-600" />
          <AiFillStar className="text-yellow-600" />
          <AiFillStar className="text-yellow-600" />
          <AiFillStar className="text-yellow-600" />
        </div>
        <p className="text-xs text-gray-500">{profile?.rating} out of 5</p>
      </div>
      <p className="text-center text-sm text-gray-500">30 Customer Reviews</p>

      <ul className="space-y-2">
        <li className="flex items-center space-x-2">
          <p className="text-sm min-w-max">5 star</p>
          <div className="bg-gray-500 rounded-2xl w-100 h-2 overflow-hidden">
            <div
              className="rounded-2xl text-center transition bg-lightgreen h-100"
              //   style={{
              //     width:
              //       (review.percentage ?? "0") + "%",
              //   }}
              style={{
                width: "" + rating?.fiveStar + "0%",
              }}
            >
              <p className="text-lightgreen hidden">.</p>
            </div>
          </div>
          <p className="text-sm w-12">{rating?.fiveStar}%</p>
        </li>
        <li className="flex items-center space-x-2">
          <div>
            {" "}
            <p className="text-sm min-w-max items-center">4 star</p>
          </div>

          <div className="bg-gray-500 rounded-2xl w-100 h-2 overflow-hidden">
            <div
              className="rounded-2xl text-center transition bg-lightgreen h-100"
              style={{
                width: "" + rating?.fourStar + "0%",
              }}
            >
              <p className="text-lightgreen text-sm hidden">.</p>
            </div>
          </div>
          <p className="text-sm w-12">{rating?.fourStar}%</p>
        </li>
        <li className="flex items-center space-x-2">
          <p className="text-sm min-w-max">3 star</p>
          <div className="bg-gray-500 rounded-2xl w-100 h-2 overflow-hidden">
            <div
              className="rounded-2xl text-center transition bg-lightgreen h-100"
              style={{
                width: "" + rating?.threeStar + "0%",
              }}
            >
              <p className="text-lightgreen hidden">.</p>
            </div>
          </div>
          <p className="text-sm w-12">{rating?.threeStar}%</p>
        </li>
        <li className="flex items-center space-x-2">
          <p className="text-sm min-w-max">2 star</p>
          <div className="bg-gray-500 rounded-2xl w-100 h-2 overflow-hidden">
            <div
              className="rounded-2xl text-center transition bg-lightgreen h-100"
              style={{
                width: "" + rating?.twoStar + "0%",
              }}
            >
              <p className="text-lightgreen hidden">.</p>
            </div>
          </div>
          <p className="text-sm w-12">{rating?.twoStar}%</p>
        </li>
        <li className="flex items-center space-x-2">
          <p className="text-sm min-w-max">1 star</p>
          <div className="bg-gray-500 rounded-2xl w-100 h-2 overflow-hidden">
            <div
              className="rounded-2xl text-center transition bg-lightgreen h-100"
              style={{
                width: "" + rating?.oneStar + "0%",
              }}
            >
              <p className="text-lightgreen hidden">.</p>
            </div>
          </div>
          <p className="text-sm w-12">{rating?.oneStar}%</p>
        </li>
      </ul>
    </div>
  ) : (
    <div className="bg-white rounded-2xl p-6 space-y-4">
      {" "}
      <p className="text-center text-sm text-gray-500">
        You have no reviews yet
      </p>
    </div>
  );
}

export default Reviews;
