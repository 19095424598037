import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Banner from "../../../components/registerSteps/Banner";
import Logo from "../../../assets/images/Care connect logo.png";

import { IoMdEyeOff } from "react-icons/io";
import { resetPassword } from "../../../store/actions/userAuth.actions";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
function ResetPasswordForm() {
  const { errors, loading, verifiedEmail } = useSelector(
    ({ UserAuth }) => UserAuth
  );
  const [password, setPassword] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [confirmpassword, setConfirmPassword] = useState(null);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const history = useHistory();
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 min-h-screen">
      <div className="bg-darkwhite flex flex-col justify-center items-center px-10">
        <div className="max-w-md w-full space-y-8">
          <div className="flex flex-col justify-center items-center">
            <img src={Logo} alt="logo" className="w-8/12" />
            <h2 className="text-3xl mt-8 text-darkblue font-bold">
              Reset Password
            </h2>
          </div>
          <form
            className="mt-8 space-y-6"
            id="submit-login"
            onSubmit={onSubmit}
          >
            <input type="hidden" name="remember" defaultValue="true" />
            <div className="space-y-3">
              <div className="flex items-center bg-white">
                <label htmlFor="password" className="sr-only">
                  Password
                </label>
                <input
                  id="password"
                  name="password"
                  type={showPassword ? "text" : "password"}
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                  className="flex-1 bg-white appearance-none rounded-none relative block w-full px-3 py-2 placeholder-darkblue text-gray-900 rounded-b-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
                  placeholder="Enter password"
                />

                <IoMdEyeOff
                  role="button"
                  className="text-gray-700 mx-3 text-xl"
                  onClick={() => setShowPassword(!showPassword)}
                />
              </div>
              {errors && errors?.password ? (
                <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
                  {errors?.password[0]}
                </div>
              ) : null}

              <div className="flex items-center bg-white">
                <label htmlFor="password" className="sr-only">
                  Confirm Password
                </label>
                <input
                  id="confirm-password"
                  name="confirm-password"
                  type={showConfirmPassword ? "text" : "password"}
                  autoComplete="confirm-password"
                  required
                  value={confirmpassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className="flex-1 appearance-none rounded-none relative block w-full px-3 py-2 placeholder-darkblue text-gray-900 rounded-b-md focus:outline-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 sm:text-sm"
                  placeholder="Confirm password"
                />

                <IoMdEyeOff
                  role="button"
                  className="text-darkblue mx-3 text-xl"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                />
              </div>
              {errors?.password_confirmation ? (
                <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
                  {errors?.password_confirmation}
                </div>
              ) : null}
            </div>
            {errors?.message ? (
              <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
                {/* {errors?.message} */}
                something went wrong,try again later
              </div>
            ) : null}

            <div>
              <button
                type="submit"
                className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-lightgreen hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightgreen"
              >
                {loading ? "Setting password..." : "Set password"}
              </button>
            </div>
          </form>
        </div>
      </div>
      <Banner />
    </div>
  );
  function onSubmit(e) {
    e.preventDefault();
    const data = {
      email: verifiedEmail,
      password: password,
      password_confirmation: confirmpassword,
    };
    dispatch(resetPassword(data, alert, history));
  }
}

export default ResetPasswordForm;
