import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import { getPatients, sendReport } from "../../store/actions";
import Shimmers from "../loaders/Shimmers";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
const modules = {
  toolbar: [
    [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ["bold", "italic", "underline", "strike"],
    [{ color: [] }, { background: [] }],
    [{ script: "sub" }, { script: "super" }],
    ["blockquote", "code-block"],
    [{ list: "ordered" }, { list: "bullet" }],
    [{ indent: "-1" }, { indent: "+1" }, { align: [] }],
    ["link"],
    ["clean"],
  ],
};
function ReportForm() {
  const { isSendingReport, errors } = useSelector(({ Reports }) => Reports);
  const { patients, fetchingPatients } = useSelector(
    ({ Patients }) => Patients
  );
  const [recentPatient, setRecentPacient] = useState(null);
  const [selectedPatient, setSelectedPatient] = useState(null);
  const [report, setReport] = useState(null);
  const dispatch = useDispatch();
  const alert = useAlert();

  const [content, setContent] = useState("");
  const handleChange = (content, editor) => {
    setContent(content);
  };

  useEffect(() => {
    dispatch(getPatients());
  }, []);
  useEffect(() => {
    if (!fetchingPatients && patients) {
      setRecentPacient(
        patients?.length && patients?.length !== 0 && patients[0]
      );
    }
  }, [fetchingPatients, patients]);
  return (
    <div className=" min-h-carditem p-6 md:p-10 mx-3.5 mx-10 flex flex-col space-y-6 bg-white rounded-3xl  md:mt-12 mb-6 col-span-2">
      <h2 className="text-darkblue text-xl font-bold text-center">Report</h2>
      <div className="space-y-2">
        <p className="text-center text-sm text-gray-500">
          Write a report to care connect indicating the completed job was
          finished.
        </p>
        <p className="text-center text-sm text-gray-500">
          NB: This report must be written at the end of each working period
        </p>
      </div>

      <form className="flex flex-col space-y-5 flex-1" onSubmit={onSendReport}>
        <div>
          <p className="text-darkblue font-bold text-sm my-2">Patient's name</p>
          {fetchingPatients ? (
            <div>
              <Shimmers width="" height="40px" className="" />
            </div>
          ) : patients?.length !== 0 ? (
            // <select
            //   className="bg-paleblue text-sm py-2 px-3 rounded-xl"
            //   onChange={(e) => setSelectedPatient(e.target.value)}

            // >
            //   <option className="text-sm px-3">Choose patient's name</option>

            //   <option value={patients[0]?.id} className="text-sm px-3" selected>
            //     {patients[0]?.full_names}
            //   </option>
            // </select>
            <p className="text-sm ">
              {patients && patients[0] && patients[0]?.full_names}
            </p>
          ) : (
            <p className="bg-paleblue text-sm py-2 px-3 rounded-xl">
              You have no recent patient for now
            </p>
          )}
        </div>
        {/* <textarea
          rows=""
          cols=""
          className="text-sm text-darkblue form-control  w-100 flex-1 rounded-xl border-gray-300  focus:shadow-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 p-4"
          id="report"
          required
          disabled={recentPatient ? false : true}
          value={report}
          onChange={(e) => setReport(e.target.value)}
          placeholder=""
        ></textarea> */}
        <div className="report-form">
          <ReactQuill
            theme="snow"
            value={content}
            modules={modules}
            onChange={handleChange}
          />
        </div>
        {errors?.description ? (
          <div className="rounded border border-danger px-3 py-2 mt-1 text-xs bg-red-100">
            {errors?.description}
          </div>
        ) : null}
        <button
          type="submit"
          disabled={patients?.length > 0 ? false : true}
          className={
            (patients?.length > 0 ? " cursor-pointer" : " cursor-default ") +
            " text-white text-sm bg-lightgreen hover:opacity-70 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-lightgreen py-2 rounded-xl relative report-btn overflow-hidden "
          }
        >
          {isSendingReport ? "Sending ..." : "Send Report"}
        </button>
      </form>
    </div>
  );
  function onSendReport(e) {
    e.preventDefault();
    // const description = report;
    const description = content;
    const patient_id = patients[0]?.id;
    dispatch(sendReport({ description, patient_id }, alert, setContent));
  }
}

export default ReportForm;
