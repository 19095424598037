import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Sidebar from ".";
import { MdClose } from "react-icons/md";
import { onUserLogout, setShowSidebar } from "../../store/actions";
import { Link, NavLink, useHistory } from "react-router-dom";
import Shimmers from "../loaders/Shimmers";
function MobileSidebar() {
  const { showSidebar } = useSelector(({ Style }) => Style);
  const {
    totalUserUnreadMessages,
    isFetchingTotalUserUnreadMessages,
    isFetchingFirebaseAdminUser,
  } = useSelector(({ Charts }) => Charts);

  const dispatch = useDispatch();
  const history = useHistory();
  return (
    <div className="h-screen sticky top-0 z-20 ">
      <div className="absolute left-0 top-0 z-30 bg-indigo-100 h-screen">
        <div className="flex flex-col items-center space-x-2 font-medium relative text-whitecolor">
          <button
            onClick={() => dispatch(setShowSidebar(false))}
            className="absolute top-0 right-0 p-2 bg-white"
          >
            <MdClose className="text-darkblue" />
          </button>

          <div className=" space-y-6 my-32 flex-1 my-10">
            <div className="flex">
              <NavLink
                to="/dashboard"
                onClick={() => dispatch(setShowSidebar(false))}
                exact
                className="w-100 px-10 flex items-center  space-x-2 text-gray-600 hover:border-r-4 hover:border-lightgreen hover:text-lightgreen hover:no-underline "
                activeClassName="text-lightgreen no-underline border-r-4 border-lightgreen"
              >
                <svg
                  width="30"
                  height="29"
                  viewBox="0 0 30 29"
                  fill="none"
                  className="w-5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M9.66683 28.3333C10.7714 28.3333 11.6668 27.4379 11.6668 26.3333V20.3333C11.6668 19.2288 12.5623 18.3333 13.6668 18.3333H16.3335C17.4381 18.3333 18.3335 19.2288 18.3335 20.3333V26.3333C18.3335 27.4379 19.2289 28.3333 20.3335 28.3333H24.6668C25.7714 28.3333 26.6668 27.4379 26.6668 26.3333V16.3866C26.6668 15.6208 27.2876 15 28.0534 15C29.3242 15 29.9256 13.4329 28.981 12.5828L16.3381 1.20413C15.5775 0.519586 14.4228 0.519588 13.6622 1.20414L1.01932 12.5828C0.0747445 13.4329 0.676111 15 1.9469 15C2.7127 15 3.3335 15.6208 3.3335 16.3866V26.3333C3.3335 27.4379 4.22893 28.3333 5.3335 28.3333H9.66683Z"
                    fill="currentColor"
                  />
                </svg>
                <p className="text-sm">Summary</p>
              </NavLink>
            </div>
            <div className="flex">
              <NavLink
                to="/dashboard/charts"
                onClick={() => dispatch(setShowSidebar(false))}
                exact
                className="w-100 px-10 flex items-center space-x-2 text-gray-600 hover:border-r-4 hover:border-lightgreen hover:text-lightgreen hover:no-underline "
                activeClassName="text-lightgreen no-underline border-r-4 border-lightgreen"
              >
                <svg
                  width="29"
                  height="26"
                  viewBox="0 0 29 26"
                  fill="none"
                  className="w-5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.54864 18.7228L3.59694 18.2287L3.29298 17.836C1.94319 16.0926 1.25004 14.1047 1.25004 12.0718C1.25004 6.31039 6.9708 1.25 14.5 1.25C22.0292 1.25 27.75 6.31039 27.75 12.0718C27.75 17.8332 22.0292 22.8935 14.5 22.8935C12.5841 22.8935 10.7431 22.5657 9.01956 21.9226L8.49134 21.7255L7.99397 21.991C6.14148 22.9798 3.82442 23.5856 2.23638 23.9159C2.41449 23.5608 2.57221 23.1826 2.71149 22.7884C3.11966 21.6329 3.39881 20.2559 3.54864 18.7228Z"
                    stroke="currentColor"
                    stroke-opacity="0.5"
                    stroke-width="2.5"
                  />
                </svg>
                <p className="text-sm">Chats</p>

                {isFetchingFirebaseAdminUser ||
                isFetchingTotalUserUnreadMessages ? (
                  <div className="rounded-circle overflow-hidden">
                    <Shimmers width="10px" height="10px" />
                  </div>
                ) : (
                  totalUserUnreadMessages !== 0 && (
                    <p
                      className={
                        (totalUserUnreadMessages &&
                        totalUserUnreadMessages !== 0
                          ? " bg-lightgreen "
                          : "") +
                        "text-xs text-whitecolor  px-1.5 py-0.5 rounded-circle"
                      }
                    >
                      {totalUserUnreadMessages ?? ""}
                    </p>
                  )
                )}
              </NavLink>
            </div>
            <div className="flex">
              <NavLink
                to="/dashboard/reports"
                onClick={() => dispatch(setShowSidebar(false))}
                exact
                className="w-100 px-10 flex items-center space-x-2 text-gray-600 hover:border-r-4 hover:border-lightgreen hover:text-lightgreen hover:no-underline "
                activeClassName="text-lightgreen no-underline border-r-4 border-lightgreen"
              >
                <svg
                  width="25"
                  height="31"
                  viewBox="0 0 25 31"
                  fill="none"
                  className="w-5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.5934 15.9288C12.5075 15.9288 9.99707 18.4393 9.99707 21.5252C9.99707 24.6111 12.5075 27.1216 15.5934 27.1216C18.6793 27.1216 21.1899 24.6111 21.1899 21.5252C21.1899 18.4393 18.6791 15.9288 15.5934 15.9288ZM15.5934 25.0706C13.6386 25.0706 12.0481 23.4801 12.0481 21.5252C12.0481 20.9201 12.2008 20.3501 12.4691 19.8513L14.8682 22.2504C15.0606 22.4427 15.3214 22.5508 15.5934 22.5508H18.987C18.5459 24.0071 17.1918 25.0706 15.5934 25.0706ZM16.0183 20.4997L13.9194 18.4009C14.4184 18.1325 14.9884 17.9798 15.5933 17.9798C17.1919 17.9798 18.546 19.0433 18.987 20.4997H16.0183Z"
                    fill="currentColor"
                    fill-opacity="0.5"
                  />
                  <path
                    d="M21.6321 3.04717H18.6816C18.321 2.15496 17.4463 1.52348 16.4264 1.52348H14.8338C14.4383 0.6272 13.5411 0 12.5 0C11.459 0 10.5617 0.6272 10.1662 1.52359H8.57356C7.5537 1.52359 6.67892 2.15507 6.3184 3.04728H3.36788C1.95702 3.04728 0.809082 4.19521 0.809082 5.60608V28.4413C0.809082 29.8522 1.95702 31 3.36788 31H21.6321C23.043 31 24.1909 29.8522 24.1909 28.4413V5.60597C24.1909 4.19511 23.043 3.04717 21.6321 3.04717ZM8.19269 3.95536C8.1928 3.74546 8.36366 3.57459 8.57356 3.57459H10.9763C11.5427 3.57459 12.0018 3.11544 12.0018 2.54909C12.0018 2.27447 12.2253 2.051 12.4999 2.051C12.7745 2.051 12.998 2.27447 12.998 2.54909C12.998 3.11544 13.4571 3.57459 14.0235 3.57459H16.4262C16.6361 3.57459 16.807 3.74546 16.807 3.95536V5.71379C16.807 5.92369 16.6361 6.09456 16.4262 6.09456H8.57346C8.36356 6.09456 8.19269 5.92369 8.19269 5.71379V3.95536ZM21.6321 28.9489H3.36788C3.08783 28.9489 2.86008 28.7211 2.86008 28.4412V5.60597C2.86008 5.32593 3.08783 5.09818 3.36788 5.09818H6.14179V5.71369C6.14179 7.05462 7.23274 8.14546 8.57356 8.14546H16.4263C17.7673 8.14546 18.8581 7.05451 18.8581 5.71369V5.09818H21.632C21.9121 5.09818 22.1398 5.32593 22.1398 5.60597V28.4412H22.1399C22.1398 28.7211 21.9121 28.9489 21.6321 28.9489Z"
                    fill="currentColor"
                    fill-opacity="0.5"
                  />
                  <path
                    d="M4.88195 11.8853H12.5001C13.0664 11.8853 13.5256 11.4261 13.5256 10.8598C13.5256 10.2934 13.0664 9.83427 12.5001 9.83427H4.88195C4.3156 9.83427 3.85645 10.2934 3.85645 10.8598C3.85634 11.4261 4.31549 11.8853 4.88195 11.8853Z"
                    fill="currentColor"
                    fill-opacity="0.5"
                  />
                  <path
                    d="M4.88195 14.9326H9.45292C10.0193 14.9326 10.4784 14.4734 10.4784 13.9071C10.4784 13.3407 10.0193 12.8816 9.45292 12.8816H4.88195C4.3156 12.8816 3.85645 13.3407 3.85645 13.9071C3.85634 14.4734 4.31549 14.9326 4.88195 14.9326Z"
                    fill="currentColor"
                    fill-opacity="0.5"
                  />
                  <path
                    d="M10.4783 16.9543C10.4783 16.388 10.0192 15.9288 9.45281 15.9288H4.88195C4.3156 15.9288 3.85645 16.388 3.85645 16.9543C3.85645 17.5207 4.3156 17.9798 4.88195 17.9798H9.45292C10.0193 17.9798 10.4783 17.5207 10.4783 16.9543Z"
                    fill="currentColor"
                    fill-opacity="0.5"
                  />
                </svg>
                <p className="text-sm">Reports</p>
              </NavLink>
            </div>
            <div className="flex">
              <NavLink
                to="/dashboard/profile"
                onClick={() => dispatch(setShowSidebar(false))}
                className="w-100 px-10 flex items-center space-x-2 text-gray-600 hover:border-r-4 hover:border-lightgreen hover:text-lightgreen hover:no-underline "
                activeClassName="text-lightgreen no-underline border-r-4 border-lightgreen"
              >
                <svg
                  width="28"
                  height="28"
                  viewBox="0 0 28 28"
                  fill="none"
                  className="w-5"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M13.9996 13.4461C16.599 13.4461 19.0276 10.9077 19.0276 7.34803C19.0276 4.72643 18.3737 3.26449 17.5559 2.44852C16.7377 1.63219 15.5427 1.25 13.9996 1.25C12.4565 1.25 11.2614 1.6322 10.4433 2.44851C9.62554 3.26447 8.97168 4.72641 8.97168 7.34801L13.9996 13.4461ZM13.9996 13.4461C11.4001 13.4461 8.97175 10.9077 8.97168 7.34803L13.9996 13.4461Z"
                    stroke="currentColor"
                    stroke-opacity="0.5"
                    stroke-width="2.5"
                  />
                  <path
                    d="M26.7206 26.0187L26.7207 26.0189C26.7672 26.1236 26.7581 26.2393 26.6953 26.336C26.6327 26.4323 26.5307 26.4877 26.4156 26.4877H1.58431C1.46913 26.4877 1.36732 26.4324 1.30477 26.3361L1.30467 26.336C1.24194 26.2395 1.23273 26.124 1.27945 26.0187L1.27946 26.0187L4.44675 18.8838C4.44675 18.8838 4.44676 18.8838 4.44677 18.8838C4.477 18.8157 4.53201 18.7573 4.59746 18.7233L4.59763 18.7232L9.00778 16.4273C10.5008 17.4157 12.2166 17.9413 14.0001 17.9413C15.7833 17.9413 17.4991 17.4157 18.9921 16.4273L23.4024 18.7232C23.4024 18.7232 23.4024 18.7232 23.4024 18.7232C23.4682 18.7574 23.5232 18.8161 23.5532 18.8836L23.5533 18.8838L26.7206 26.0187ZM19.4815 16.0806C19.4803 16.0815 19.4791 16.0825 19.4779 16.0834C19.479 16.0826 19.4801 16.0818 19.4811 16.081L19.4815 16.0806Z"
                    stroke="currentColor"
                    stroke-opacity="0.5"
                    stroke-width="2.5"
                  />
                </svg>
                <p className="text-sm">Profile</p>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 left-10">
          <div className="flex justify-center">
            <hr className="w-11/12 text-gray-600" />
          </div>
          <div className="flex justify-center items-center my-4">
            <button
              className="p-2 bg-pagebg rounded-3 text-lightgreen hover:opacity-70 flex justify-center items-center space-x-2"
              onClick={() => dispatch(onUserLogout())}
              // onClick={() => history.push("/signin")}
            >
              <svg
                width="35"
                height="35"
                viewBox="0 0 35 35"
                fill="none"
                className="w-5"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.54999 16C7.24641 16 7 16.2464 7 16.55V19.8501C7 20.1536 7.24641 20.4001 7.54999 20.4001C7.85357 20.4001 8.09998 20.1536 8.09998 19.8501V16.55C8.10005 16.2464 7.85364 16 7.54999 16Z"
                  fill="currentColor"
                />
                <path
                  d="M8.12147 0.913061L8.12145 0.913011L8.11669 0.914918L2.91929 2.99444C2.16686 3.27342 1.61414 3.61496 1.25437 4.10119C0.891941 4.59102 0.75 5.19399 0.75 5.94315V29.0486C0.75 29.7978 0.891926 30.4005 1.25236 30.8897C1.61031 31.3754 2.15958 31.716 2.90552 31.9929L8.12714 34.0818L8.12713 34.0819L8.13146 34.0835C8.41583 34.1912 8.70533 34.2471 8.99223 34.2471C10.2678 34.2471 11.1502 33.1769 11.1502 31.7998V3.19314C11.1502 2.34484 10.8109 1.62019 10.2498 1.1826C9.68471 0.741861 8.91952 0.612602 8.12147 0.913061ZM3.48434 30.5012L3.48437 30.5011L3.4783 30.4989C2.96449 30.3085 2.70169 30.1496 2.55498 29.9581C2.41357 29.7736 2.35005 29.5186 2.35005 29.0486V5.94315C2.35005 5.47319 2.41356 5.21874 2.55615 5.03393C2.70446 4.84169 2.97066 4.68147 3.49153 4.48848L3.49156 4.48856L3.49752 4.48618L8.69819 2.40591C8.80875 2.36448 8.90721 2.34638 8.99165 2.34638C9.1486 2.34638 9.27814 2.41433 9.376 2.54705C9.47812 2.68554 9.55017 2.90341 9.55017 3.19314V31.7998C9.55017 32.0896 9.47821 32.3076 9.37615 32.4463C9.27836 32.5791 9.14898 32.6471 8.99223 32.6471C8.90668 32.6471 8.80749 32.6285 8.70821 32.5917L3.48434 30.5012Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0.5"
                />
                <path
                  d="M13.4969 4.34288H20.5155C21.2016 4.34288 21.8062 4.99533 21.8062 5.85716V14.4286C21.8062 14.8271 22.0924 15.2 22.5031 15.2C22.9138 15.2 23.2 14.8271 23.2 14.4286V5.85716C23.2 4.19847 22.0213 2.8 20.5155 2.8H13.4969C13.0868 2.8 12.8 3.17271 12.8 3.57141C12.8 3.97007 13.0868 4.34288 13.4969 4.34288Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0.4"
                />
                <path
                  d="M23.2 20.5713V20.5713C23.1999 20.1716 22.9113 19.8 22.5 19.8C22.0886 19.8 21.8 20.1716 21.8 20.5714V29.1429C21.8 30.0035 21.1923 30.6572 20.5 30.6572H13.5C13.0893 30.6572 12.8 31.0287 12.8 31.4286C12.8 31.8285 13.0893 32.2 13.5 32.2H20.5C22.0132 32.2 23.2 30.8028 23.2 29.1428V20.5713Z"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0.4"
                />
                <path
                  d="M33.9586 17.2833C33.9306 17.2156 33.8904 17.1551 33.8393 17.104L28.3397 11.6039C28.1246 11.3888 27.777 11.3888 27.562 11.6039C27.3469 11.8189 27.3469 12.1665 27.562 12.3816L32.1232 16.9433H13.6506C13.347 16.9433 13.1006 17.1897 13.1006 17.4933C13.1006 17.7969 13.347 18.0433 13.6506 18.0433H32.1233L27.562 22.6046C27.3469 22.8197 27.3469 23.1673 27.562 23.3823C27.6692 23.4896 27.8101 23.5435 27.9508 23.5435C28.0917 23.5435 28.2324 23.4896 28.3397 23.3823L33.8393 17.8828C33.8904 17.8316 33.9306 17.7711 33.9586 17.7034C34.0142 17.5693 34.0142 17.4175 33.9586 17.2833Z"
                  fill="currentColor"
                  stroke="currentColor"
                />
              </svg>
              <p className="text-sm">Logout</p>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileSidebar;
