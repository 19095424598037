import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
function ReportDocument() {
  const { selectedReport } = useSelector(({ Reports }) => Reports);
  return (
    <div className="p-10 space-y-4">
      <div>
        <h5 className="font-bold text-sm text-darkblue">Report</h5>
        <p className=" text-xs text-gray-700">
          {" "}
          {moment(selectedReport?.created_at).format("MMMM Do YYYY, h:mm:ss a")}
        </p>
      </div>
      {/* <div>
        <textarea
          disabled
          rows=""
          cols=""
          className="text-sm text-darkblue form-control my-2 w-100 flex-1 rounded-xl border-gray-300  focus:shadow-none focus:ring-lightgreen focus:border-lightgreen focus:z-10 p-4 overflow-auto"
          style={{ height: "60vh" }}
          id="report"
          required
          value={selectedReport?.description}
        ></textarea>
      </div> */}

      <div className="read-report">
        <ReactQuill theme="snow" value={selectedReport?.description} readOnly />
      </div>
    </div>
  );
}

export default ReportDocument;
