import {
  getUserRequest,
  loginRequest,
  registerRequest,
  resetPasswordRequest,
  updateUserRequest,
  verifyEmailRequest,
} from "../../api";
import actionTypes from "./types";
import { removeUserStorage, setUserStorage } from "../../utils/userUtils";
import { axiosInstance } from "../../api/config";
import AlertMessage from "../../components/alert/Alert";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
} from "firebase/auth";
import { auth, db, storage } from "../../firebase";
import { doc, setDoc, Timestamp, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { GetValidUsersImage } from "../../utils/image";
import { async } from "@firebase/util";
export const registerUser = (data, history, prev) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.RegisterUser });
    registerRequest(data)
      .then((res) => {
        var userInfo = { token: res?.token, user: res?.data };
        setUserStorage(userInfo);
        axiosInstance.defaults.headers.common = {
          Authorization: `Bearer ${res?.token}`,
        };
        dispatch({ type: actionTypes.RegisterUserSuccess, payload: res?.data });
        history.push("/dashboard/completeprofile");
      })
      .catch((err) => {
        if (
          err?.errors?.email ||
          err?.errors?.password ||
          err?.errors?.password_confirmation ||
          err?.errors?.full_names ||
          err?.errors?.phone_number ||
          err?.errors?.avatar
        ) {
          dispatch({ type: actionTypes.RegisterUserFail, payload: err.errors });
          prev();
        } else {
          dispatch({ type: actionTypes.RegisterUserFail, payload: err });
        }
      });
  };
};

export const signupFirebase = (email, password, name, profilePic) => {
  return async () => {
    try {
      const result = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      let url;
      if (profilePic) {
        const imgRef = ref(
          storage,
          `users-images/${new Date().getTime()} - ${profilePic.name}`
        );
        const snap = await uploadBytes(imgRef, profilePic);
        const dlUrl = await getDownloadURL(ref(storage, snap.ref.fullPath));
        url = dlUrl;
      }
      await setDoc(doc(db, "users", result.user.uid), {
        uid: result.user.uid,
        name: name,
        email,
        createdAt: Timestamp.fromDate(new Date()),
        isOnline: true,
        role: "Nurse",
        avatar: url || "",
      });
    } catch (err) {
      console.log("Error!", err);
    }
  };
};

export const loginUser = (data, history) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LogUserIn });
    loginRequest(data)
      .then((res) => {
        if (res?.data?.role) {
          if (
            res?.data?.role?.length &&
            res?.data?.role[0]?.name === "Approved nurse"
          ) {
            setUserStorage(res?.data);
            axiosInstance.defaults.headers.common = {
              Authorization: `Bearer ${res?.data.token}`,
            };
            dispatch({
              type: actionTypes.LogUserInSuccess,
              payload: res?.data?.user,
            });
          } else {
            var error = { message: "Invalid email/password. Try again. " };
            dispatch({ type: actionTypes.LogUserInFail, payload: error });
          }
        }

        // history.push("/dashboard/completeprofile");
        // history.push("/dashboard/completeprofile");
      })
      .catch((err) => {
        if (err?.errors) {
          dispatch({ type: actionTypes.LogUserInFail, payload: err.errors });
        } else {
          dispatch({ type: actionTypes.LogUserInFail, payload: err });
        }
      });
  };
};
export const loginFirebase = (email, password) => {
  return async () => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password);
      await updateDoc(doc(db, "users", result.user.uid), {
        isOnline: true,
      });
    } catch {
      alert("Error!");
    }
  };
};
export const getUser = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.getUser });
    getUserRequest()
      .then((res) => {
        dispatch({ type: actionTypes.getUserSuccess, payload: res });
      })
      .catch((err) => {
        dispatch({ type: actionTypes.getUserFail, payload: err });
      });
  };
};

export const updateUser = (data, setEditProfile, activePane, alert) => {
  return async (dispatch, getState) => {
    dispatch({ type: actionTypes.updateUser });
    updateUserRequest(data)
      .then((res) => {
        if (res && res.data) {
          if (activePane === "account-details") {
            const user = JSON.parse(localStorage.getItem("user"));
            var userInfo = { token: user?.token, user: res?.data[0] };
            setUserStorage(userInfo);
            dispatch({
              type: actionTypes.updateUserSuccess,
              payload: res?.data[0],
            });

            dispatch(
              updateFirebaseUser({
                name: res?.data[0]?.full_names,
                email: res?.data[0]?.email,
                avatar: res?.data[0]?.avatar,
              })
            );

            setEditProfile(false);
            alert.success(
              <AlertMessage message="Profile successfully updated !" />
            );
          } else {
            dispatch({
              type: actionTypes.updateUserSuccess,
              payload: res?.data[0],
            });
            setEditProfile(false);
            alert.success(
              <AlertMessage message="Password successfully updated !" />
            );
          }
        }
      })
      .catch((err) => {
        dispatch({ type: actionTypes.updateUserFail, payload: err });
        alert.error(
          <AlertMessage message="Something went wrong, trye again later ." />
        );
      });
  };
};
export const saveFirebaseUser = () => {
  return async (dispatch, getState) => {
    const userInfo = getState().UserAuth.userInfo;
    try {
      await setDoc(doc(db, "users", userInfo?.id), {
        uid: userInfo?.id,
        name: userInfo?.full_names,
        email: userInfo?.email,
        createdAt: Timestamp.fromDate(new Date()),
        isOnline: true,
        role: "Nurse",
        avatar: GetValidUsersImage(userInfo?.avatar) || "",
      });
    } catch (err) {
      console.log("Error!", err);
    }
  };
};
export const updateFirebaseUser = (data) => {
  return async (dispatch, getState) => {
    const userInfo = getState().UserAuth.userInfo;
    try {
      await updateDoc(doc(db, "users", userInfo?.id), {
        name: data?.name,
        email: data?.email,
        avatar: GetValidUsersImage(data?.avatar) || "",
      });
      await signOut(auth);
    } catch {
      return;
    }
  };
};
export const markFirebaseUserOnline = () => {
  return async (dispatch) => {};
};
export const markFirebaseUserOffline = () => {
  return async (dispatch) => {};
};
export function logoutFirebase() {
  return async () => {
    try {
      await updateDoc(doc(db, "users", auth.currentUser.uid), {
        isOnline: false,
      });
      await signOut(auth);
    } catch {
      alert("Error!");
    }
  };
}
export const onUserLogout = () => {
  return (dispatch) => {
    dispatch({ type: actionTypes.LogUserOut });
    removeUserStorage();
  };
};
export const verifyEmail = (data, history) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.verifyEmail });
    verifyEmailRequest(data)
      .then((res) => {
        dispatch({ type: actionTypes.verifyEmailSuccess, payload: res });
        dispatch({ type: actionTypes.saveVerifiedEmail, payload: data?.email });
        history.push("/account/password/reset/edit");
      })
      .catch((err) => {
        if (err?.errors) {
          dispatch({ type: actionTypes.verifyEmailFail, payload: err.errors });
        } else {
          dispatch({ type: actionTypes.verifyEmailFail, payload: err });
        }
      });
  };
};

export const resetPassword = (data, alert, history) => {
  return (dispatch) => {
    dispatch({ type: actionTypes.resetPassword });
    resetPasswordRequest(data)
      .then((res) => {
        dispatch({ type: actionTypes.resetPasswordSuccess, payload: res });
        dispatch({ type: actionTypes.saveVerifiedEmail, payload: null });
        history.push("/signin");
        alert.success(
          <AlertMessage message="Password Successfully changed !" />
        );
      })
      .catch((err) => {
        if (err?.errors) {
          dispatch({ type: actionTypes.verifyEmailFail, payload: err.errors });
        } else {
          dispatch({ type: actionTypes.verifyEmailFail, payload: err });
        }
      });
  };
};
