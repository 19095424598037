import React from "react";
import Moment from "react-moment";
import userImg from "../../../assets/images/user_img.png";
function SentConversation({ chart }) {
  return (
    <div className="grid grid-cols-3 ">
      <div></div>
      <div className=" col-span-2 flex justify-end">
        <img
          src={userImg}
          alt="profile"
          className="w-16 h-16 order-2 ml-2 rounded-circle border-lightgreen border-1"
        />
        <div>
          <div className="flex flex-col text-gray-500">
            <p className="p-3 rounded-tl-3xl rounded-br-3xl bg-lightgreen text-white text-sm w-48 md:w-64">
              {chart?.text}
            </p>
            <Moment
              className="text-xs justify-self-start"
              format="LLLL"
              fromNow
            >
              {chart.createdAt.toDate()}
            </Moment>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SentConversation;
