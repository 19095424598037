import React, { useEffect, useRef } from "react";
import { BsDot } from "react-icons/bs";
import Moment from "react-moment";
import { useSelector } from "react-redux";
import userImg from "../../../assets/images/Care connect logo2.png";
import ConversationForm from "./ConversationForm";
import ReceivedConversation from "./ReceivedConversation";
import SentConversation from "./SentConversation";
function Conversation({ currentUser }) {
  const { charts, selectedChart, selectedUser } = useSelector(
    ({ Charts }) => Charts
  );
  const scrollRef = useRef();

  useEffect(() => {
    scrollRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [selectedChart]);

  return (
    <div className="rounded-3xl overflow-hidden relative ">
      <div className="bg-white flex  items-center p-6 h-100">
        <img
          src={userImg}
          alt="Profile"
          className="rounded-circle border-lightgreen border-1"
        />
        <div className="mx-3">
          <h5 className="font-bold text-darkblue text-base">
            {selectedUser?.name}
          </h5>
          <p className="text-xs text-gray-500">{selectedUser?.role ?? ""}</p>
        </div>
      </div>
      <div className=" bg-white mt-2 py-10 px-16 h-96 lg:h-chatitem lg:sticky lg:top-0  overflow-auto">
        <ul className="space-y-6 ">
          {selectedChart?.length > 0 &&
            selectedChart?.map((chart, index) =>
              chart?.from === currentUser?.id ? (
                <div key={index} className="space-y-6" ref={scrollRef}>
                  <div className="flex justify-center">
                    {/* {chart?.createdAt?.seconds && (
                      <Moment
                        className="text-sm px-5 py-1.5 bg-paleblue rounded-3xl text-darkblue font-bold"
                        format="YYYY/MM/DD"
                        fromNow
                      >
                        {chart.createdAt.toDate()}
                      </Moment>
                    )} */}
                  </div>
                  <SentConversation chart={chart} />

                  <div></div>
                </div>
              ) : (
                <div key={index} className="space-y-6">
                  <div className="flex justify-center">
                    {/* {chart?.createdAt?.seconds && (
                      <Moment
                        className="text-sm px-5 py-1.5 bg-paleblue rounded-3xl text-darkblue font-bold"
                        format="YYYY/MM/DD"
                        fromNow
                      >
                        {chart.createdAt.toDate()}
                      </Moment>
                    )} */}
                  </div>
                  <ReceivedConversation chart={chart} />

                  <div></div>
                </div>
              )
            )}
        </ul>
      </div>
      <ConversationForm currentUser={currentUser} />
    </div>
  );
}

export default Conversation;
